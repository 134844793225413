

.dobra1{
   width: 100%;
   display: flex; flex-direction: column; justify-content: center; align-items: center;
   background:linear-gradient(45deg,#320000, #000 ); padding: 48px 0;
}

.dobra2{
   width: 100%;
   display: flex; flex-direction: column; justify-content: center; align-items: center;
   background:linear-gradient(to right,#910101, #000 ); padding: 48px 0;
}

.dobra3{
   width: 100%;
   display: flex; flex-direction: column; justify-content: center; align-items: center;
   background:linear-gradient(135deg,#DFBA00, #9D0000 ); padding: 48px 0;
}

.dobra4{
   width: 100%;
   display: flex; flex-direction: column; justify-content: center; align-items: center;
   background:linear-gradient(45deg,#910101, #000 ); padding: 48px 0;
}

.dobra5{
   width: 100%;
   display: flex; flex-direction: column; justify-content: center; align-items: center;
   background:linear-gradient(to top,#910101, #000 ); padding: 48px 0;
}

.dobra6{
   width: 100%;
   display: flex; flex-direction: row; justify-content: center; align-items: center;
   background: #000 ; padding: 48px 0; gap: 20px;
}

.dobra7{
   width: 100%;
   display: flex; flex-direction: column; justify-content: center; align-items: center;
   background: #910101 ; padding: 48px 0; gap: 20px;
}

.dobra8{
   width: 100%;
   display: flex; flex-direction: column; justify-content: center; align-items: center;
   background: #000 ; padding: 16px 0; gap: 20px;
}

.dobra1--sessoes{
   display: flex; flex-direction: row; margin-left: 100px; margin-right: 100px;
   gap: 20px; 
}

.db1--sessao1{
   width: 430px; 
}

.db1--sessao2{
   width: 430px; 
}

.db2--sessao1{
   width: 300px; display: flex; align-items: center;
}

.db2--sessao2{
   display: flex; flex-direction: column;
   width: 505px; color: #fff; justify-content: left;
}

.db4--sessao1{
   width: 355px; display: flex; align-items: center;
}

.db4--sessao2{
   display: flex; flex-direction: column;
   width: 505px; color: #fff; justify-content: left;
}


.sessao--titulo{
   color: #FFD400; font-family: Smooch Sans; font-weight: 700;
   font-size: 48px; text-transform: uppercase; text-align: left; line-height: 1;
}

.sessao--texto{
   color: #fff; font-family: Roboto Condensed ;
   font-size: 24px; text-align: left;
}

.sessao--botao{
   width: 100%; height: 64px; display: flex; align-items: center; justify-content: center;
   color: #fff; font-family: Smooch Sans; font-weight: 700; font-size: 40px; margin-top: 32px;
   background-size: 400% 200%; transition: background 5s ease, box-shadow 0.5s; cursor: pointer;
   animation: brilho 2.4s cubic-bezier(0.2, 0.5, 0.9, 0.6) 0s infinite; user-select: none;
   background-image: linear-gradient(45deg, #FF0000, #FFD400, #FF0000, #FFD400); border-radius: 12px;
}

.txDestaque1{
color: #fff;
}

.txDestaque2{
   color: #FFD400;
   }

.logoSDI{
animation: zoomImg 3s ease-in-out infinite;
}

.mckSDI{
   
   animation: zoomImg2 6s ease-in-out infinite;
  }

@keyframes zoomImg {
   0%{transform: scale(1);}
   50%{transform: scale(1.1);}
   100%{transform: scale(1);}
 }

 @keyframes zoomImg2 {
   0%{transform: scale(1);}
   50%{transform: scale(1.015);}
   100%{transform: scale(1);}
 }

 /*p{
   font-family: Roboto Condensed; font-size: 24px; margin-bottom: 0px;
 }*/

 .chega{
   font-family: Smooch Sans; font-size: 58px; font-weight: 700; line-height: 0.8; text-align: left;
 }

 .agenda{
   background: linear-gradient(to top, #FF0000, #FF8686);
   -webkit-background-clip: text;
   
   color: transparent;
   -webkit-text-fill-color: transparent;
   
   background-clip: text; font-size: 64px;
 }

 .beneficio{
   width: 205px; height: auto; padding: '16px'; display: flex; flex-direction: column; justify-content: center; align-items: center;
   background: rgba(255, 255, 255, 0.1); border: 1px solid rgba(255, 255, 255, 0.5); border-radius: 8px;
  
 }

 .blocoBN{
   display: flex; flex-direction: row; align-items: center; justify-content: center; gap: 20px; margin-top: 32px;
 }

 .subtitulo{
   font-family: Smooch Sans; font-size: 126px; font-weight: 700;
   line-height: 0.8; text-align: left;
   background: linear-gradient(to top, #FF0000, #FFD600);
   -webkit-background-clip: text;
   
   color: transparent;
   -webkit-text-fill-color: transparent;
   
   background-clip: text; 
 }

 .subtitulo2{
   font-family: Smooch Sans; font-size: 126px; font-weight: 700;
    text-align: left;
    background: linear-gradient(to top, #FF0000, #FFD600);
   -webkit-background-clip: text;
   
   color: transparent;
   -webkit-text-fill-color: transparent;
  
   background-clip: text; 
 }

 .subtitulo3{
   font-family: Smooch Sans; font-size: 126px; font-weight: 700;
    text-align: left; color: #fff;
   
 }

 .subtitulo4{
   font-family: Smooch Sans;
    background: linear-gradient(-135deg, #FF0000, #FFD600);
   -webkit-background-clip: text;
   
   color: transparent;
   -webkit-text-fill-color: transparent;
  
   background-clip: text; 
 }

 
 .question{
   width: 450px; color: #FFD600; font-size: 20px; font-weight: 700;
 }


 @media(min-width: 730px){
  
 #d1d{
   display: none;
 }

/* p{
   font-family: Roboto Condensed; font-size: 18px; margin-bottom: 0px;
 }*/

 #d2d{
   display: none;
 }

 #d3d{
   display: none;
 }

 #d4d{
   display: none;
 }

 #d6d{
   display: none;
 }

 #d7d{
   display: none;
 }


 .db2--sessao2{
   display: flex; flex-direction: column;
   width: 350px; color: #fff; justify-content: left;
}

.db2--sessao1{
   width: 233px; display: flex; align-items: center;
}

.dobra1--sessoes{
   display: flex; flex-direction: row; margin-left: 100px; margin-right: 100px;
    
}

.beneficio{
   width: 275px; height: auto; padding: 16px;  display: flex; flex-direction: row; justify-content: center; align-items: center; 
   background: rgba(255, 255, 255, 0.1); border: 1px solid rgba(255, 255, 255, 0.5); border-radius: 8px;
  
 }

 #d1m{
   display: none;
 }
 #d2m{
   display: none;
 } 
 
 #d3m{
   display: none;
 } 
 #d4m{
   display: none;
 } 

 #d6m{
   display: none;
 } 

 #d7m{
   display: none;
 } 
 #d03d{
   display: none;
 } 

 }

 @media(max-width: 730px){
   #d1t{
       display: none;
     }
     #d2t{
       display: none;
     } 
     
     #d3t{
       display: none;
     } 
     #d4t{
       display: none;
     } 
     #d6t{
       display: none;
     } 

     #d7t{
       display: none;
     } 

     .dobra1{
       width: -webkit-fill-available;
       display: flex; flex-direction: column; justify-content: center; align-items: center;
       background:linear-gradient(45deg,#320000, #000 ); padding: 48px 24px;
   }

   .sessao--botao{
       width: 300px; height: 64px; display: flex; align-items: center; justify-content: center;
       color: #fff; font-family: Smooch Sans; font-weight: 700; font-size: 32px; margin: 32px auto 0px auto;
       background-size: 400% 200%; transition: background 5s ease, box-shadow 0.5s; cursor: pointer;
       animation: brilho 2.4s cubic-bezier(0.2, 0.5, 0.9, 0.6) 0s infinite;
       background-image: linear-gradient(45deg, #FF0000, #FFD400, #FF0000, #FFD400); border-radius: 12px;
   }

   .beneficio{
       width: 312px; height: auto; padding: 8px;  display: flex; flex-direction: row; justify-content: center; align-items: center; 
       background: rgba(255, 255, 255, 0.1); border: 1px solid rgba(255, 255, 255, 0.5); border-radius: 8px;
      
     }

     .question{
       width: 310px; color: #FFD600; font-size: 20px; font-weight: 700;
     }

     #d1d{
       display: none;
     }
   
    
   
     #d2d{
       display: none;
     }
   
     #d3d{
       display: none;
     }
   
     #d4d{
       display: none;
     }
   
     #d6d{
       display: none;
     }
   
     #d7d{
       display: none;
     }
     #d03d{
       display: none;
     } 
 }

 @media(min-width: 1024px){
   #d1d{
       display: flex;
     }
   
    
   
     #d2d{
       display: flex;
     }
   
     #d3d{
       display: flex;
     }
   
     #d4d{
       display: flex;
     }
   
     #d6d{
       display: flex;
     }
   
     #d7d{
       display: flex;
     }

     #d03d{
       display: flex;
     } 

    


   #d1t{
     display: none;
   }

   #d2t{
       display: none;
     }
     #d3t{
       display: none;
     }

     #d4t{
       display: none;
     }
 
     #d6t{
       display: none;
     }

     #d7t{
       display: none;
     }



     #d1m{
       display: none;
     }
     #d2m{
       display: none;
     } 
     
     #d3m{
       display: none;
     } 

     #d4m{
       display: none;
     } 
     #d6m{
       display: none;
     } 

     #d7m{
       display: none;
     } 

     #d03t{
       display: none;
     } 

     .beneficio{
       width: 205px; height: 207px; display: flex; flex-direction: column; justify-content: center; align-items: center;
       background: rgba(255, 255, 255, 0.1); border: 1px solid rgba(255, 255, 255, 0.5); border-radius: 8px;
      
     }

    
   }

