body {
  margin: 0; padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Roboto Serif', 'Roboto Slab', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Alegreya',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #191919;

}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*HEADER*/
.bgHeader{
  display: flex; flex-direction: row; justify-content: space-between; align-items: center;
  background: linear-gradient(90deg, #191919 0%, #808080 150% );
  height: 72px; padding: 0px 15%; position: fixed; top: 0; left: 0; width: -webkit-fill-available;
  position: relative; z-index: 1; transform: translateY(-80px);
  animation: InHeader 0.5s ease-in-out forwards; 
}



.divider{
  width: 100%; height: 2px; position: absolute; bottom: 0; left: 0;
  background: linear-gradient(90deg,#dffc01, rgba(223,252,1,0));

}

.headerCantoA{
  height: 24px; width: 24px; position: absolute; top: 0; left: 0;
  transform: rotate(180deg);
}
.headerCantoD{
  height: 24px; width: 24px; position: absolute; bottom: 0; right: 0;
}

.logoKtalus{
  height: 32px;
}

.perfil1{
  display: flex; flex-direction: row; justify-content: right;
  align-items: center;
  opacity: 0; animation: fadeProgress 1s ease-in 0.5s forwards;
}

.perfil2{
  display: flex; flex-direction: row; justify-content: left;
  align-items: center; width: 100%;
 gap: 8px; padding: 0 16px 0 0; box-sizing: border-box; position: relative;
}


.nomeEstudio{ width: 155px; color: #dffc01;
  font-size: 22px; font-weight: 700;  line-height: 1;
}




.borderUser{
  display: inline-flex; justify-content: center; align-items: center; width: 50px; height: 50px;
   border: 2px solid #dffc01; border-radius: 100%; margin-left: 16px;
}



.fotoPerfil{
  width: 42px; height: 42px; border-radius: 100%; background: #929292;
}
