
@keyframes brilho {0% {background-position: 100% 0;} 100% {background-position: 0 0;}}

.tDet--button{
    display: flex; justify-content: center; align-items: center;
    width: 100%; height: 48px; margin-top: 16px; border-radius: 16px;
    font-size: 20px; font-weight: 700; cursor: pointer; opacity: 0;
    background-size: 400% 200%; transition: background 5s ease, box-shadow 0.5s;
    animation: brilho 6.4s cubic-bezier(0.2, 0.5, 0.9, 0.6) 0s infinite,
    fadeBtTT 0.7s ease-in 0.6s both;
}

.tDet--button2{
    display: flex; justify-content: center; align-items: center;
    width: 100%; height: 48px; margin-top: 16px; border-radius: 16px;
    font-size: 20px; font-weight: 700; cursor: pointer; opacity: 1;
    background-size: 400% 200%; transition: background 3s ease, box-shadow 0.5s;
    animation: brilho 3s cubic-bezier(0.2, 0.5, 0.9, 0.6) 0s infinite;
    /*fadeBtTT 0.7s ease-in-out .3s both;*/
}

.btVermelho{
    background-image: linear-gradient(70deg,
    #680000, #CC0101, #680000, #CC0101);
    border: 2px solid #FF0000;
}
.btLaranja{
    background-image: linear-gradient(70deg,
    #683400, #CC6601, #683400, #CC6601);
    border: 2px solid #FF8000;
}
.btAmarelo{
    background-image: linear-gradient(70deg,
    #685700, #CCAA01, #685700, #CCAA01);
    border: 2px solid #FFD400;
}
.btVerde{
    background-image: linear-gradient(70deg,
    #01CC01, #006800, #01CC01, #006800);
    border: 2px solid #00FF00;
}
.btCiano{
    background-image: linear-gradient(70deg,
    #01CCCC, #006868, #01CCCC, #006868);
    border: 2px solid #00FFFF;
}
.btAzul{
    background-image: linear-gradient(70deg,
    #003468, #0166CC, #003468, #0166CC);
    border: 2px solid #0080FF;
}
.btRoxo{
    background-image: linear-gradient(70deg,
    #460068, #8801CC, #460068, #8801CC);
    border: 2px solid #AA00FF;
}
.btRosa{
    background-image: linear-gradient(70deg,
    #680057, #CC01AA, #680057, #CC01AA);
    border: 2px solid #FF00D4;
}
.btMagenta{
    background-image: linear-gradient(70deg,
    #680023, #CC0145, #680023, #CC0145);
    border: 2px solid #FF0055;
}
.btBranco{
    background-image: linear-gradient(70deg,
    #686868, #B3B3B3, #686868, #B3B3B3);
    border: 2px solid #CCCCCC;
}

.btKtalus{
    background-image: linear-gradient(70deg,
    #718001, #dffc01, #718001, #dffc01);
    border: 2px solid #dffc01;
}