#page{
    display: flex; flex-direction: column; 
    margin-bottom: 16px; margin-top: 80px;
    padding-bottom: 32px; 
   align-items: center; justify-content: start;
    overflow-y:auto ; overflow-x: hidden;
    /*scrollbar-width: none; -ms-overflow-style: none;
    &::-webkit-scrollbar { display: none;}*/

}

.cabecalho{
    height: 80px;  width: 100%; background: rgba(255,255,255,0.07); 
     display: flex; flex-direction: row; position: fixed; top: 0; right: 0;
      justify-content: left; align-items: center; color: white;
      font-size: 24px; gap: 16px; font-weight: 700; text-align: left; z-index: 2;
      padding: 0 16px; box-shadow: 0px 8px 24px black; transform: translateY(-90px);
      animation: cab 1s ease-in forwards; cursor: pointer;
      
      
}

@keyframes cab{
    0%{transform: translateY(-90px);}100%{transform: translateY(0px);}
}

.cabVermelho{ background: #520000 ; }
.cabLaranja{ background: #522900 ; }
.cabAmarelo{ background: #524500 ; }
.cabVerde{ background: #005200 ; }
.cabCiano{ background: #005252; }
.cabAzul{ background: #002952 ; }
.cabRoxo{ background: #370052 ; }
.cabRosa{ background: #520044 ; }
.cabMagenta{ background: #520044 ; }
.cabBranco{ background: #525252 ; }


.perfilBC{
    
    width: 56px; height: 56px; 
    
   margin-left: 32px; 
    border-radius: 100%; 
    display: flex; justify-content: center; align-items: center;
    
}


.perfilPC{
    width: 48px; height: 48px; border-radius: 100%; background: #E3BD00;
}


/*Seção*/

.secaoC{
    position: relative;
    color: white; font-weight: 700;
    display: flex; flex-direction: column; justify-content: left; font-size: 24px;
    width: 100%; text-align: left; margin-left: 24px; margin-right: 24px; 
    box-sizing: border-box; padding-left: 16px; margin-top: 8px;
    /*animation: topF 0.7s ease-in-out 1.5s forwards; transform: translateX(50%); opacity: 0;*/
}

.sin{
    display: flex; flex-direction: row; 
    overflow-x:auto;  align-items: center; overflow-y: hidden;
    scrollbar-width: none; -ms-overflow-style: none;
    white-space: nowrap; margin-top: 4px; margin-bottom: 16px;
     height: auto; box-sizing: border-box;

    &::-webkit-scrollbar { display: none;}
}

.sin::after {
    content: "";
    position: absolute;
    top: 0;
    width: 50px; /* Largura do fade */
    height: 100%;
    z-index: 2;
    pointer-events: none;
    left: 16px;
    background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

.titleTop{
    display: flex; flex-direction: row; align-items: center;
     font-size: 32px; color: white;
}

.cabTop{
    display: flex; flex-direction: row; justify-content: space-between; 
}

.secaoTop{
    position: relative;
    color: white; font-weight: 700;
    display: flex; flex-direction: column;  justify-content: left; font-size: 28px;
    width: 95%; text-align: left; margin-left: 24px; margin-right: 24px; gap: 16px;
    box-sizing: border-box; padding-left: 16px; margin-top: 32px; margin-bottom: 24px;
    
}

@keyframes topF {
    0%{ opacity: 0;}100%{ opacity: 1;}
}

/*.secaoTop::after{
    content: "";
    position: absolute;
    top: 0; right: 0;
    width: 32px; height: 264px;
    background-image: linear-gradient(to right, rgba(25,21,0,0), rgba(25,21,0,0.5));

}

.secaoC::after{
    content: "";
    position: absolute;
    top: 0; right: 0;
    width: 32px; height: 196px;
    background-image: linear-gradient(to right, rgba(25,21,0,0), rgba(25,21,0,0.5));

}*/

.secaoT{
    
    display: flex; flex-direction: row; 
    overflow-x:auto;  align-items: center; overflow-y: hidden;
    scrollbar-width: none; -ms-overflow-style: none;
    white-space: nowrap; margin-top: 4px; margin-bottom: 16px;
    width: 100%; height: auto; box-sizing: border-box;

    &::-webkit-scrollbar { display: none;}
}

.secaoTop1{
    
    display: flex; flex-direction: row; 
    overflow-x:auto; gap: 24px; align-items: center; 
    scrollbar-width: none; -ms-overflow-style: none;
    white-space: nowrap; 
    width: 100%; 

    &::-webkit-scrollbar { display: none;}
}

.nextBackTop{
    display: flex; flex-direction: row; gap: 16px; color: #fff; z-index: 2;
}

.tattooS{
    width: 115px; height: 144px; 
    display: flex; justify-content: center; align-items: center;
    border-radius: 16px; flex-shrink: 0; color: #3F3400; position: relative;
}



.TattoComPosicao{
     position: relative; 
     flex-shrink:0; height: 240px; display: flex; justify-content: center;
}

.posicao{
    height: 113px; width: auto; position: absolute; left: 0;
    z-index: 0; top: 64px;
}

.tattooTop{
    display: flex;
    width: 156px; height: 196px; margin-top: auto; margin-bottom: auto;
    border-radius: 16px; color: #3F3400; margin-left: 51px;
    z-index: 1;
    transition: all ease 0.2s; cursor: pointer;
    
    
}

.ttVermelho{
    border: 2px solid #FF0000;
    box-shadow: 0px 0px 16px #FF0000;
}
.ttLaranja{
    border: 2px solid #FF8000;
    box-shadow: 0px 0px 16px #FF8000;
}
.ttAmarelo{
    border: 2px solid #FFD400;
    box-shadow: 0px 0px 16px #FFD400;
}
.ttVerde{
    border: 2px solid #00FF00;
    box-shadow: 0px 0px 16px #00FF00;
}
.ttCiano{
    border: 2px solid #00FFFF;
    box-shadow: 0px 0px 16px #00FFFF;
}
.ttAzul{
    border: 2px solid #0080FF;
    box-shadow: 0px 0px 16px #0080FF;
}
.ttRoxo{
    border: 2px solid #AA00FF;
    box-shadow: 0px 0px 16px #AA00FF;
}
.ttRosa{
    border: 2px solid #FF00D4;
    box-shadow: 0px 0px 16px #FF00D4;
}
.ttMagenta{
    border: 2px solid #FF0055;
    box-shadow: 0px 0px 16px #FF0055;
}
.ttBranco{
    border: 2px solid #CCCCCC;
    box-shadow: 0px 0px 16px #CCCCCC;
}

.tattooTop:hover{
    display: flex;
    width: 170px; height: 212px; background-color: aliceblue;
    border-radius: 16px; color: #3F3400; margin-left: 51px;
    z-index: 1; 
}


.tTopC{ display: flex; flex-direction: column; align-items: center; justify-content: start;
 width: 16px; height: 64px; padding: 4px 4px; background: #E3BD00;
 color: black;  position: absolute;
 top: 0; right: 0; border-radius: 0px 8px 0px 8px; font-size: 14px; font-weight: 700;
 box-shadow: 0px 2px 8px black; line-height: 1.1;
}

.topVermelho{ background: #FF0000 ; color: white; }
.topLaranja{ background: #FF8000 ; color: white; }
.topAmarelo{ background: #FFD400 ; }
.topVerde{ background: #00FF00 ;  }
.topCiano{ background: #00FFFF; }
.topAzul{ background: #0080FF ; color: white;}
.topRoxo{ background: #AA00FF ; color: white;}
.topRosa{ background: #FF00D4 ; color: white;}
.topMagenta{ background: #FF0055 ; color: white;}
.topBranco{ background: #CCCCCC ; }

.icVermelho{ color: #FF0000 ;  }
.icLaranja{ color: #FF8000 ;  }
.icAmarelo{ color: #FFD400 ; }
.icVerde{ color: #00FF00 ;  }
.icCiano{ color: #00FFFF; }
.icAzul{ color: #0080FF ; }
.icRoxo{ color: #AA00FF ; }
.icRosa{ color: #FF00D4 ; }
.icMagenta{ color: #FF0055 ; ;}
.icBranco{ color: #CCCCCC ; }

.imgTattoo{
    width: 90%; height: 90%; background: white; position: relative;
    border-radius: 16px; transition: all ease 0.2s; cursor: pointer;
    
}

.imgT{
    width: 100%; height: 100%; border-radius: 8px;
}

.imgTattoo:hover{
    width: 100%; height: 100%; background: white; border-radius: 8px;
}

/*#page::after{
    content: "";
    position: absolute;
    bottom: 0; left: 0;
    width: 100%; height: 64px;
    background-image: linear-gradient(to bottom, rgba(25,21,0,0), rgba(25,21,0,1));
   
}*/

.moveLeft, .moveRight{
    position: absolute;
    width: 40px; height: 144px;
    background: rgba(0,0,0,0.6);
    z-index: 10;
    display: flex; align-items: center; justify-content: center;
    overflow: hidden;
    cursor: pointer; opacity: 0;
    transition: all ease 0.2s;
}

.moveLeft{
    left: 0;
}

.moveRight{
    right: 0;
}

.tattooTop--img{
    width: 100%; height: 100%; border-radius: 14px;
}

.secaoC:hover .moveLeft, .secaoC:hover .moveRight{
    opacity: 1;
}

.btNXPV{
    display: flex; align-items: center; justify-content: center;
    width: 40px; height: 40px; background: rgba(255,255,255,0.1);
    border-radius: 100%;
}

@media(min-width: 600px) {
    .tattooS{
        width: 155px; height: 194px; 
        border-radius: 16px; flex-shrink: 0; color: #3F3400;
    }


    .tattooTop{
        width: 226px; height: 286px; background-color: aliceblue;
        border-radius: 16px; flex-shrink: 0; color: #3F3400; margin-left: 72px;
    }

    .tattooTop:hover{
        width: 242px; height: 301px; background-color: aliceblue;
        border-radius: 16px; flex-shrink: 0; color: #3F3400; margin-left: 72px;
    }

   
    

    /*.secaoTop::after{
        content: "";
        position: absolute;
        top: 0; right: 0;
        width: 32px; height: 364px;
        background-image: linear-gradient(to right, rgba(25,21,0,0), rgba(25,21,0,0.5));
    
    }
    
    .secaoC::after{
        content: "";
        position: absolute;
        top: 0; right: 0;
        width: 32px; height: 266px;
        background-image: linear-gradient(to right, rgba(25,21,0,0), rgba(25,21,0,0.5));
    
    }*/

    .posicao{
        height: 161px; width: auto; position: absolute; left: 0;
        z-index: 0; top: 88px;
    }

    .TattoComPosicao{
        position: relative;
        flex-shrink:0; height: 340px; display: flex; justify-content: center;
   }

  

.moveLeft, .moveRight{
    position: absolute;
    width: 40px; height: 194px;
    background: rgba(0,0,0,0.6);
    z-index: 10;
    display: flex; align-items: center; justify-content: center;
    overflow: hidden;
    cursor: pointer; opacity: 0;
    transition: all ease 0.5s;
}
}
    



@media(min-width: 1024px) {
    .cabecalho{
        height: 80px;  width: 100%; background: rgba(255,255,255,0.07); 
         display: flex; flex-direction: row; position: fixed; top: 0; right: 0;
          justify-content: left; align-items: center; color: white;
          font-size: 32px; gap: 16px; font-weight: 700; text-align: left; z-index: 2;
          padding: 0 16px; box-shadow: 0px 8px 24px black; transform: translateY(-90px);
          animation: cab 1s ease-in forwards; cursor: pointer;
          
          
    }

    .perfilBC{
    
        width: 56px; height: 56px; 
        
       margin-left: 64px; 
        border-radius: 100%; 
        display: flex; justify-content: center; align-items: center;
        
    }
}

@media(min-width: 600px) {
    .cabecalho{
        height: 80px;  width: 100%; background: rgba(255,255,255,0.07); 
         display: flex; flex-direction: row; position: fixed; top: 0; right: 0;
          justify-content: left; align-items: center; color: white;
          font-size: 32px; gap: 16px; font-weight: 700; text-align: left; z-index: 2;
          padding: 0 16px; box-shadow: 0px 8px 24px black; transform: translateY(-90px);
          animation: cab 1s ease-in forwards; cursor: pointer;
          
          
    }
    .perfilBC{
    
        width: 56px; height: 56px; 
        
       margin-left: 48px; 
        border-radius: 100%; 
        display: flex; justify-content: center; align-items: center;
        
    }
}

@media(max-width: 900px) {
    .imgTattoo{
        width: 100%; height: 100%; background: white; position: relative;
        border-radius: 16px; transition: all ease 0.2s; cursor: pointer;
        
    }
    
    .secaoT{
    
        display: flex; flex-direction: row; 
        overflow-x:auto;  align-items: center;
        scrollbar-width: none; -ms-overflow-style: none;
        white-space: nowrap; margin-top: 4px; margin-bottom: 16px;
        width: 100%; height: auto; gap: 4px;
    
        &::-webkit-scrollbar { display: none;}
    }
    
    .imgTattoo:hover{
        width: 100%; height: 100%; background: white; border-radius: 16px;
    }

    .moveLeft, .moveRight{
        display: none;
    }
}



