.bgProgress{
    display: block; position: fixed; top: 0; left: 0;
    width: -webkit-fill-available;  height: 100%; 
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex;  flex-direction: column; justify-content: center; 
    align-items: center; box-sizing: border-box;   z-index: 1000;  overflow: auto;
    
    user-select: none
}

.bgProgressSave{
    display: block; position: fixed; top: 0; left: 0;
    width: -webkit-fill-available;  height: 100%; 
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex;  flex-direction: column; justify-content: center; 
    align-items: center; box-sizing: border-box;   z-index: 1000;  overflow: auto;
    backdrop-filter: blur(10px);
    user-select: none
}
.bgProgressC{
  display: block; position: fixed; top: 0; left: 0;
  width: -webkit-fill-available;  height: 100%; 
  /*background-color: rgba(0, 0, 0, 0.7);*/ 
  display: flex;  flex-direction: column; justify-content: center; 
  align-items: center; box-sizing: border-box;   z-index: 1000;  overflow: auto;
  backdrop-filter: blur(10px);
  user-select: none
}


.progress{
    display: flex; flex-direction: column; margin: auto; width: 90%; align-items: center; justify-content: center;
    min-width: 339px; max-width: 400px; height: auto; padding: 32px 0;
    /*background: linear-gradient(to right, #252525, #000);*/
    font-size: 18px;
     box-sizing: border-box; border-radius:8px; color: #fff; position: relative;
     user-select: none; /*border: 2px solid rgba(254,236,110,0.3);*/
     opacity: 0; animation: fadeProgress 0.1s ease-in  forwards;

  }

@keyframes fadeProgress{
    0%{opacity: 0;}
    100%{opacity: 1;}

}


  .progress--logo{
    
  }

  .progress--reflex{
    width: 100%; height: 100%;  position: absolute; top: 0; 
    background-size: 400% 200%; transition: background 6s ease-in-out;
    background-image: linear-gradient(115deg,
    rgba(255,255,255,0), rgba(255,255,255,0.25), rgba(255,255,255,0), rgba(255,255,255,0.25),  rgba(255,255,255,0), rgba(255,255,255,0.25),  rgba(255,255,255,0));
 border-radius: 0px 16px 0px 16px;
    animation: brilho 2.4s cubic-bezier(0.2, 0.5, 0.9, 0.6) 0s infinite;
  }
 

  .progress-img{
    width: auto; height: 32px;
  }

  .progress--container{
    
    width: 180px ; height: 4px ; border-radius: 16px;
    background: #000; border: 1px solid #fff ;
    margin-top: 24px;
  }

  .progress--barra{
    
    height: 4px ; border-radius: 16px;
    background: #fff;
  }


  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
  