#moldura{
    display: flex; margin: auto; z-index: 1; box-sizing: border-box;
    position: fixed; width: 95%; height: 95%;
    top: 50%; left: 50%; transform: translate(-50%, -50%);  
}

#borda{
    display: flex;
    position: fixed; width: 100%; height: 100%;
    animation: animacaoBrilho 2s ease-in-out; border-radius: 28px;
}

/*Cores*/

.bordVermelho{border: 2px solid rgba(255,0,0,0.35); z-index: 2;}
.bordLaranja{border: 2px solid rgba(255,128,0,0.35); z-index: 2;}
.bordAmarelo{border: 2px solid rgba(255,212,0,0.35); z-index: 2;}
.bordVerde{border: 2px solid rgba(0,255,0,0.35); z-index: 2;}
.bordCiano{border: 2px solid rgba(0,255,255,0.35); z-index: 2;}
.bordAzul{border: 2px solid rgba(0,128,255,0.35); z-index: 2;}
.bordRoxo{border: 2px solid rgba(170,0,255,0.35); z-index: 2;}
.bordRosa{border: 2px solid rgba(255,0,212,0.35); z-index: 2;}
.bordMagenta{border: 2px solid rgba(255,0,85,0.35); z-index: 2;}
.bordBranco{border: 2px solid rgba(204,204,204,0.35); z-index: 2;}






/*Animações*/

@keyframes animacaoBrilho {0% {opacity: 0;} 100% {opacity: 1;}}

.cantoA{
    width: 64px; position: absolute;top: 0px; left: 0px; z-index: 5;
    transform: rotate(180deg); animation: moverA 1s;
}

@keyframes moverA{
    from{transform: rotate(180deg) translate(32px,32px);}
    to{transform: rotate(180deg) translate(0,0);}
}

.cantoB{
    width: 64px;  position: absolute;top: 0px; right: -3px; z-index: 5;
    transform: rotate(270deg); animation: moverB 1s;
}

@keyframes moverB{
    from{transform: rotate(270deg) translate(32px,32px); }
    to{transform: rotate(270deg) translate(0,0);}
}

.cantoC{
    width: 64px; position: absolute;bottom: -4px; left: -1px; z-index: 5;
    transform: rotate(90deg); animation: moverC 1s;
}
@keyframes moverC{
    from{transform: rotate(90deg) translate(32px,32px); }
    to{transform: rotate(90deg) translate(0,0);}
}


.cantoD{
    width: 64px; position: absolute;bottom: -3px; right: -3px;
    animation: moverD 1s; z-index: 5;
}

@keyframes moverD{
    from{transform:  translate(32px,32px); }
    to{transform:  translate(0,0);}
}

@media(min-width: 1024px){
    .cantoA{
        width: 88px; position: absolute;top: 0px; left: 0px; z-index: 5;
        transform: rotate(180deg); animation: moverA 1s;
    }
    
  
    
    .cantoB{
        width: 88px;  position: absolute;top: 0px; right: -3px; z-index: 5;
        transform: rotate(270deg); animation: moverB 1s;
    }
    
   
    
    .cantoC{
        width: 88px; position: absolute;bottom: -4px; left: -1px; z-index: 5;
        transform: rotate(90deg); animation: moverC 1s;
    }
   
    
    
    .cantoD{
        width: 88px; position: absolute;bottom: -3px; right: -3px;
        animation: moverD 1s; z-index: 5;
    }
}