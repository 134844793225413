
.tDet--bg{
    display: block;position: fixed;
    top: 0;left: 0;
    width: -webkit-fill-available;  height: 100%; 
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    box-sizing: border-box; opacity: 0;
    z-index: 2000;overflow: auto; user-select: none;
    backdrop-filter: blur(4px);
    animation: bgTT 0.2s ease-in both; 
    
 }

 @keyframes bgTT {
    0%{opacity: 0;}
    100%{opacity: 1;}
 }


    .tDet--card{
        display: flex; flex-direction: column; margin: auto; position: relative;
        justify-content: center;  padding: 32px;
        width: 356px;  height: auto; 
        box-sizing: border-box; border-radius: 16px;  
        animation: cardTT 0.2s ease-in 0.2s both ; transform: scale(0); 
    }

    .cardVermelho{background: linear-gradient(to top, #190000, #330000);}
    .cardLaranja{background-color: #190D00;}
    .cardAmarelo{background: linear-gradient(to top, #191500, #332B00);}
    .cardVerde{background: linear-gradient(to top, #001900, #003300);}
    .cardCiano{background: linear-gradient(to top, #001919, #003333);}
    .cardAzul{background: linear-gradient(to top, #000D19, #001A33);}
    .cardRoxo{background: linear-gradient(to top, #110019, #220033);}
    .cardRosa{background-color: #190015;}
    .cardMagenta{background-color: #190008;}
    .cardBranco{background: linear-gradient(to top, #191919, #262626);}

    .tttVermelho{color:  rgba(255,0,0,1);}
    .tttLaranja{color:  rgba(255,128,0,1);}
    .tttAmarelo{color:  rgba(255,212,0,1);}
    .tttVerde{color:  rgba(0,255,0,1),;}
    .tttCiano{color:  rgba(0,255,255,1);}
    .tttAzul{color:  rgba(0,128,255,1);}
    .tttRoxo{color:  rgba(170,0,255,1);}
    .tttRosa{color:  rgba(255,0,212,1);}
    .tttMagenta{color:  rgba(255,0,85,1);}
    .tttBranco{color:  rgba(204,204,204,1);}

    .bordCVermelho{border: 1px solid rgba(255,0,0,0.5);}
    .bordCLaranja{border: 1px solid rgba(255,128,0,0.5);}
    .bordCAmarelo{border: 1px solid rgba(255,212,0,0.5);}
    .bordCVerde{border: 1px solid rgba(0,255,0,0.5);}
    .bordCCiano{border: 1px solid rgba(0,255,255,0.5);}
    .bordCAzul{border: 1px solid rgba(0,128,255,0.5);}
    .bordCRoxo{border: 1px solid rgba(170,0,255,0.5);}
    .bordCRosa{border: 1px solid rgba(255,0,212,0.5);}
    .bordCMagenta{border: 1px solid rgba(255,0,85,0.5);}
    .bordCBranco{border: 1px solid rgba(204,204,204,0.5);}

    @keyframes cardTT {
        0%{transform: scale(0);}
        100%{transform: scale(1);}
     } 

    .tDet--title{
          text-align: left; text-align: center;
         margin-top: 8px; font-size: 20px; word-wrap: break-word; flex-wrap: wrap;
        font-weight: 700; margin-left: 64px; opacity: 0; margin-right: 0; padding: 4px 0px 4px 0px;
        animation: tittle 0.5s ease-in-out 0.4s forwards; border: none; box-shadow: 0 0 0 0; 
        
    }

    @keyframes tittle {
        0%{opacity: 0; margin-left: 64px;}
        100%{opacity: 1; margin-left: 0px;}
    }

    .tDet--slides{
        display: flex; flex-direction: row; 
        overflow-x:auto; gap: 24px; align-items: center;
        scrollbar-width: none; -ms-overflow-style: none;
        white-space: nowrap; 
        width: 286px; 
    
        &::-webkit-scrollbar { display: none;}
       
    }

    

    .tDet--Desc{
        color: white; font-size: 14px; width: 286px; text-align: center;
        font-weight: 400;   word-wrap: break-word;  /* adicionado */
        white-space: normal;  margin-top: -64px; margin-bottom: 8px; padding-right: 4px; line-height: 1.1;
        opacity: 0;  max-height: 100px; overflow-y: auto;
        animation: desc 0.5s ease-in-out 0.4s forwards;
        
    }

    @keyframes desc {
        0%{opacity: 0; margin-top: -64px;}
        100%{opacity: 1; margin-top: 8px;}
    }

   

    @keyframes fadeBtTT{
        0%{opacity: 0;}
        100%{opacity: 1;}
    }

    /*.tDet--cta{
        background: linear-gradient(to right, #FFEA7F, #FFD400);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    }*/

    .tDet--cantoA, .tDet--cantoD{
        height: 56px; width: 56px; position: absolute;
    }

    .tDet--cantoA{
        top: -1px; left: -1px;
        transform: rotate(180deg);
    }
    .tDet--cantoD{
        bottom: -1px; right: -1px;
    }

    .tDet--close{
        display: flex; align-items: center; justify-content: center;
        font-size: 32px; color: white; z-index: 3;
        position: absolute; top: 0; right: -0px;
        margin: 4px; width: 32px; height: 32px; background: rgba(255,255,255,0.05); border-radius: 4px;
    }


    .tDet--slides1{
        display: flex; flex-direction: row; z-index: 1;
        overflow-x:auto; align-items: center;
        scrollbar-width: none; -ms-overflow-style: none;
        white-space: nowrap; margin: 0 auto; overflow: hidden;
        width: 100%; max-width: 286px; position: relative;
    
        /*&::-webkit-scrollbar { display: none;}*/
        }


    .slideIn::before{ 
        position: absolute; bottom: 0; left: 0; content: "";
        display: flex; flex-direction: column; z-index: 2;
       width: 100%; height: 64px; 
       border-radius: 0px 0px 8px 8px;
       background: linear-gradient(to bottom, rgba(0,0,0,0), #000) ;
        }

    .slide-item{
        width: 100%; height: 357px;
    }

    .slideIn{ 
        width: 286px; height: 357px;  flex: 0 0 auto;
        display: flex; justify-content: center; align-items: center;
        border-radius: 16px;  color: #3F3400; position: relative;
    }



    .imgSlide{
        width: 286px; height: 357px; border-radius: 16px;
    }

    .slide--btR, .slide--btL{
        width: 40px; height: 40px; position: absolute;
         background: rgba(0,0,0,0.5); color: white;
         font-size: 24px;  border-radius: 100%;
         text-align: center; align-items: center; justify-content: center;
         border: 1px solid rgba(255,255,255,0.5);
    }

    .slide--btPlus{
        width: 24px; height: 24px; position: absolute;
         background: rgba(0,0,0,0.5); color: white;
         font-size: 16px;  border-radius: 100%;
         text-align: center; align-items: center; justify-content: center;
         border: 1px solid rgba(255,255,255,0.5); bottom: 8px;  z-index: 3;
        animation: plus 3s ease-in-out infinite;
        }

    @keyframes plus{
        0%{transform: translateX(4px);}50%{transform: translateX(-4px);}100%{transform: translateX(4px);}
    }

    .slide--btL{
        
        left: 0px; 
    }

    .slide--btR{
        
        right: 0px; 
    }

    .tDet--tamanho{
        color: #fff; font-size: 16px; z-index: 3;
        position: absolute; bottom: 0; left: 0; 
        margin-bottom: 4px; margin-left: 4px; background-color: rgba(0, 0, 0, 0.5); padding: 2px 8px 2px 8px; border-radius: 16px;
    }

    .tDet--preco{
        color: #6dff50; font-size: 16px; z-index: 3;
        position: absolute; bottom: 0; right: 0;
        margin-bottom: 4px; margin-right: 4px;  background-color: rgba(0, 0, 0, 0.5); padding: 2px 8px 2px 8px; border-radius: 16px;
    }

@media(max-width: 1024px){
    
}

.mobile{
     display: none;
   
}

.desktop{
       display:flex;
    
}


.carousel-container {
    position: relative;
    width: 286px;
    margin: auto;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
   
    cursor: grab; height: 357px; width: 286px; 
  }
  
  .carousel-item {
    min-width: 286px;
    height: 357px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d3436;
    border-radius: 10px;
    overflow: hidden; border-radius: 16px;
  }
  
  .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  
  /* Setas de Navegação */
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 32px;
    cursor: pointer;
    padding-top: 12px;
    z-index: 10;
    width: 32px; height: 32px; border-radius: 50%;
    display: flex; justify-content: center; align-items: center;
  }
  
  .carousel-button.left {
    left: 10px;  user-select: none; /* Impede a seleção de texto */
    -webkit-touch-callout: none; -webkit-tap-highlight-color: transparent;
  }
  
  .carousel-button.right {
    right: 10px; user-select: none; /* Impede a seleção de texto */
    -webkit-touch-callout: none; -webkit-tap-highlight-color: transparent;
  }
  
  /* Bolinhas de Indicadores */
  .carousel-dots {
    position: absolute;
    bottom: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dot.active,
  .dot:hover {
    background-color: #fff;
  }


  