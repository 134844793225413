.termopage{
    width: 90%; max-width: 600px; 
    display: flex; flex-direction: column; justify-content: start; align-items: start;
    margin: 32px auto; border: 1px none white; font-family: 'Roboto';
}

.logoK{
    height: 56px;
}

.termo--cab{
    display: flex; flex-direction: row; gap: 16px;
    justify-content: start; align-items: center;
}

h1{
    font-size: 24px; color: #dffc01; font-weight: 700;
}

h2{
    font-size: 18px; color: #dffc01; font-weight: 700; 
}

h3{
    font-size: 16px; color: #Fff; font-weight: 700; 
}

p, li{
    font-size: 16px; text-align: left; color: #Fff; 
}

a{
    font-size: 16px; text-align: left; color: #dffc01; 
}