/*{
    border: 1px none red; 
}*/

body{
   
    box-sizing: border-box;
    overflow-y: auto;
}

.page{
    position: fixed; top: 0; left: 0;
    width: 100%; height: 100%;
    background: #191919;
   
    font-family: 'Roboto'; 
    display: flex; flex-direction: column; align-items: center; justify-content: center;
}

h2{
    color: #dffc01; text-align: left; font-size: 22px; font-family: 'Roboto';
    font-weight: 700; 
}

.pr{
    display: flex; flex-direction: column; align-items: center; 
    
   margin: 24px; width: -webkit-fill-available; max-width: 400px; /*height: 100vh; max-height: 1920px;*/
   background: linear-gradient(135deg, #191919, #000);
   border: 2px solid #dffc01;
   border-radius: 16px;
    padding: 24px; box-sizing: border-box;
    overflow-y: auto; font-family: 'montserrat'; position: relative;

}

.prP{
    display: flex; flex-direction: column; align-items: center; justify-content: start;
    
   margin: 24px; width: -webkit-fill-available; max-width: 400px;
   background: linear-gradient(135deg, #191919, #000); 
   border: 2px solid #dffc01; border-radius: 16px;  box-sizing: border-box;
     padding: 16px; font-family: 'montserrat'; position: relative; overflow: auto;
}

.pr--labels{
    display: flex; flex-direction: column;
    align-items:center; justify-content: center;
    width: ; gap: 8px;
    color: white; /*padding-top: 32px;*/ 
    /*background-color: rgba(255,255,255,0.15); */

}

.pr--labelsPerso{
    display: flex; flex-direction: column;
    align-items:start; justify-content: center;
    width: -webkit-fill-available; gap: 16px;
    color: white; padding: 16px; box-sizing: border-box;

}

.pr--cabP1{
    display: flex; flex-direction: row; gap: 16px;
}

input{
    /*margin-bottom: 24px;*/
}

label02{
    font-weight: 400; font-size: 16px; text-align: left; color: #dffc01;
user-select: none;}

.pr--whats{
    display: flex; flex-direction: row; gap: 16px; width: 100%;
}

.pr--link{
    color: #dffc01;  font-weight: 400; font-style: italic; margin-top: 0;
user-select: none;}

.pr--linkNome{
    color: #fff; font-weight: 700;
}

.pr--verif{
    display: flex; flex-direction: row; width: 100%; position: relative;
}

.pr--btVerificar{
    background: #fff; color: #16A124; font-weight: 700; 
    height: 50px; padding: 0;
}

.pr--prevCont{
    display: flex; flex-direction: column;
    width: -webkit-fill-available; 
    padding: 8px; 
    border-radius: 16px; gap: 8px;
}

.pr--prevCont--title{
color: #dffc01;
}


.disponivel, .indisponivel, .verificando{
    font-size: 32px;
    position: absolute; right: 10px; top: 35%;
    transform: translateY(-35%);
}

.disponivel{
    color: #2CFA41;
    display: block;
}

.indisponivel{
    color: #ff7a7a;
    display: block;
}

.verificando{
    color: rgba(255,255,255,0.7);
    display: block;
    animation: girar 0.3s infinite linear ;
}

@keyframes girar{
    0%{transform: translateY(-35%) rotate(0deg);}
    100%{transform: translateY(-35%) rotate(360deg);}
}


.pr--buttons{
    display: flex; flex-direction: row; justify-content: space-between;
    width: 100%; gap: 16px; font-size: 16px;  font-family: 'montserrat';
    /*position: absolute; bottom: 16px;*/ padding: 16px; box-sizing: border-box;
}

.pr--back{
    margin: 0; height: 54px; border-radius: 16px; font-family: 'montserrat';
    border: 1px solid #dffc01; background: transparent; color: #dffc01;
}

.pr--next{
    margin: 0; height: 54px; border-radius: 16px; font-family: 'montserrat';
    background: #dffc01; color: #000; font-weight: 700;
}


.pr--labels2{
    display: flex;  flex-direction: column;
    align-items:center; justify-content: center;
    width: -webkit-fill-available;
    color: white; 
}

.pr--upload{
    display: flex; flex-direction: column; 
    justify-content: center; align-items: center;
    width: 194px; height: 194px; 
    border: 2px solid #dffc01; font-size: 20px; 
    border-radius: 100%; cursor: pointer; color: #dffc01;
}

.prevFoto{
    display: flex; flex-direction: column; 
    justify-content: center; align-items: center;
    width: 194px; height: 194px; 
    border: 2px solid white; font-size: 20px; 
    border-radius: 100%; margin-bottom: 16px;
}

.pr--previewFoto{
    display: flex; flex-direction: column;
    align-items:center; justify-content: center;
    width: -webkit-fill-available;
    color: white;  
}

.remover{
    display: flex; flex-direction: row; align-items: center; justify-content: center;
    height: auto; padding: 8px 16px; gap: 8px; color: #dffc01;
     background: rgba(223,252,1,0.1); border-radius: 20px; cursor: pointer;
}

.alertP{
    display: none;
    font-size: 14px; color: #dffc01; background: rgba(223,252,1,0.1); height: auto;
     align-items: center; justify-content: center; width: 100%;
     font-weight: 700; margin-bottom: 8px; padding: 8px 0; border-radius: 40px;
    
}

.crop-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999; 
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .crop-popup-hidden {
    display: none;
  }

.cores{
    display: flex; flex-direction: column; overflow-y: auto; height: 130px;
    border-radius: 16px; border: 1px solid rgba(255,255,255,0.3); gap: 24px;
    margin-top: 8px;
}

  .cores--estilo{
    display: flex; flex-direction: column; 
    justify-content: left; align-items: start;
     width: -webkit-fill-available; padding: 8px;
     
  }

  .cores--cores{
    display: flex; flex-direction: row; gap: 8px; flex-wrap: wrap;
    margin-top: 8px;
  }

  .Color{
    display: flex; justify-content: center; align-items: center;
    width: 48px; height: 48px; border-radius: 8px;
    font-size: 24px; cursor: pointer;
  }

.fonte{
    display: flex;  flex-direction: row; justify-content: space-around; align-items: center;
    width: 100%; margin-top: 8px; flex-wrap: wrap; gap: 8px;
} 

  .fonte--bt{
    display: flex; justify-content: center; align-items: center;
    width: 100px; height: 48px; background: rgba(217,217,217,0.1);
    border-radius: 16px; font-size: 16px; cursor: pointer;
  }

  .ftActive{
    border: 1px solid white;font-weight: 700; background: rgba(217,217,217,0.25);
  }

  

.previewPerfil{
    width: 100%; height: 72px; border-radius: 16px; background: rgba(255,255,255,0.3);
    /*margin-top: 8px; margin-bottom: 24px;*/ font-size: 20px; font-weight: 700;
    display: flex; flex-direction: row; align-items: center; justify-content: center;
    position: relative; color: #fff;
}

.previewPerfilb{
    width: 100%; height: 72px; border-radius: 16px; 
    margin-top: 24px; margin-bottom: 24px; 
    display: flex; flex-direction: row; align-items: center; justify-content: center;
    position: absolute;   z-index: 1;
      border: 2px solid rgba(0,0,0,0.5);
}

.p--Info{
    font-size: 12px; color: rgba(217,217,217,0.5);margin: 0 auto;  /*margin-top: -20px; */
}

.prepreviewPerfil-foto{
    width: 42px; height: 42px; border-radius: 100%;
}

.prepreviewPerfil-borda{
    display: flex; justify-content: center; align-items: center;
    width: 50px; height: 50px; border-radius: 100%; 
    border: 2px solid white; margin-right: 16px;
}


.estilo{
    display: flex; flex-direction: row; flex-wrap: wrap;
    justify-content: center; align-items: center;
     gap: 8px; 
}

.estilo--container{
    width: 80px; height: 80px; border-radius: 16px; background: rgba(217,217,217,0.1);
    justify-content: center; align-items: center; cursor: pointer;
}
.estiloActive{
    border: 2px solid #dffc01;font-weight: 700; background: rgba(223,252,1,0.1);
  }

.estilo--img{
    width: 72px; height: 72px;
}

.Prev--cantoA{
    width: 38px; height: 38px;
    position: absolute; top: -3px; left: -3px; transform: rotate(180deg);
}

.Prev--cantoB{
    width: 38px; height: 38px; 
    position: absolute; top: -3px; right: -3px; transform: rotate(270deg);
}

.Prev--cantoC{
    width: 38px; height: 38px; 
    position: absolute; bottom: -3px; left: -3px; transform: rotate(90deg);
}

.Prev--cantoD{
    width: 38px; height: 38px; 
    position: absolute; bottom: -3px; right: -3px; z-index: 1;
}


.fontRoboto{font-family: 'Roboto';}
.fontSlab{font-family: 'Roboto slab';}
.fontSerif{font-family: 'Roboto serif';}

.pagePadrao{background: linear-gradient(to top, #191919, #262626);}
.pageVer{background: linear-gradient(to top, #190000, #330000);}
.pageVerd{background: linear-gradient(to top, #001900, #003300);}
.pageAzu{background: linear-gradient(to top, #000D19, #001A33);}
.pageRox{background: linear-gradient(to top, #110019, #220033);}
.pageCia{background: linear-gradient(to top, #001919, #003333);}
.pageAma{background: linear-gradient(to top, #191500, #332B00);}


.bVer{border: 2px solid #FF0000;}
.bLan{border: 2px solid #FF8000;}
.bAma{border: 2px solid #FFD400;}
.bVerd{border: 2px solid #00FF00;}
.bCia{border: 2px solid #00FFFF;}
.bAzu{border: 2px solid #0080FF;}
.bRox{border: 2px solid #AA00FF;}
.bRos{border: 2px solid #FF00D4;}
.bMag{border: 2px solid #FF0055;}
.bBra{border: 2px solid #CCCCCC;}



@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  