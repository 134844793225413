/* Área de membros */

.CBody{ margin: 0px auto; max-width: 674px; padding: 0 64px;
    display: flex; flex-direction: column; flex-wrap: wrap; justify-content: center; width: -webkit-fill-available;
    
    }
    
    .abas{
      margin: 88px 0px 32px 0px; width: 100%; max-width: 674px; 
      height: 48px; display: flex; flex-direction: row; justify-content: space-around; align-items: center;
      background-color: #24272E; border-radius: 16px; color: #fff;
    }
    
    .alert{
      font-size: 14px; color: #dffc01; background: rgba(223, 252, 1, 0.1); height: auto;
      display: flex; align-items: center; justify-content: center; width: 100%;
      font-weight: 400; margin-bottom: 8px; padding: 8px 0;
  }
    
    
    .btLink, .btCatalogo{
      background-color: rgba(255,255,255, 0.3);
      height: 32px; width: 25%; display: flex; justify-content: center; align-items: center;
      border-radius: 8px; font-weight: 700; cursor: pointer;
    }
    
    .abaLink{ /*inline-flex*/
      display: inline-flex; flex-direction: column; width: -webkit-fill-available;  justify-content: center;
    }
    
    .abas.active-link {
      background: transparent; font-weight: 400;
    }
    
    .abas.active-catalogo {
      background: transparent; font-weight: 400;
    }
    
    .qrB{
      
      background-color: white;
      display: flex; width: 348px; height: 348px; justify-content: center; align-items: center;
      border-radius: 12px; margin: 0 auto;
    }
    
    .qrC{
      border-radius: 8px; width: 300px; height: 300px;
    }
    
    .icon{
      font-size: 24px;
    }
    .iconTop{
      font-size: 24px; margin: auto 0px;
    }
    
    .iconCat{
      font-size: 32px;
    }
    
    .link{
      display: flex; flex-direction: row; justify-content: center; align-items: center;
      border: 2px solid #FB9214; color: #Fff; height: 48px; border-radius: 8px;
      font-size: 18px; gap: 16px; font-weight: 700; margin-top: 32px;
      background: linear-gradient(90deg, #291600 0%, #753F00 50%, #291600 100%);
    }
    
    .copyLink{
      display: flex; flex-direction: row; justify-content: center; align-items: center;
       color: #Fff; height: 48px; border-radius: 8px;
      font-size: 18px; gap: 16px; font-weight: 700;
      background: #FB9214; margin-top: 16px;
    }
    
    .abaCat{
      display: flex; flex-direction: column; justify-content: center;
      width: -webkit-fill-available;  margin: 24px; padding: 16px; box-sizing: border-box;
      border-radius: 16px;
    }
    
    .addSecao{
      display: flex; flex-direction: row; justify-content: center; align-items: center;
       color: #ffffff; height: 48px; border-radius: 16px;
      font-size: 18px; gap: 8px; font-weight: 700; padding: 0 16px 0 16px;
      background: linear-gradient(to bottom, #718001 60%, #dffc01 130%); 
      cursor: pointer; border: #dffc01 solid 2px;
    }

    .addTattoo{
      display: flex; flex-direction: row; justify-content: center; align-items: center;
       color: #fff; height: 48px; border-radius: 16px; width: 225px;
      font-size: 18px; gap: 16px; font-weight: 700; margin: 0px auto 32px auto;
      background: linear-gradient(to bottom, #718001 60%, #dffc01 130%);  cursor: pointer; border: #dffc01 solid 2px;
    }

    .addTattoox2{
      display: flex; flex-direction: row; justify-content: center; align-items: center;
       color: #dffc01; height: 48px; border-radius: 16px; width: 225px;
      font-size: 18px; gap: 16px; font-weight: 700; margin: -24px auto 32px auto;
        cursor: pointer; border: #dffc01 solid 2px;
    }
    
    @media(max-width: 900px) {
      .bgHeader {padding: 0px 16px; }
    
      .nomeEstudio {display:none; }
    
      .CBody{ 
        margin: 0px 16px; padding: 0;
        display: flex; /*flex-direction: column;*/ justify-content: center;}
    
      .qrB{
        background-color: white;
        display: flex; width: 248px; height: 248px; justify-content: center; align-items: center;
        border-radius: 12px; margin: 0 auto;
      }
    
      .qrC{
        width: 200px; height: 200px;
      }
    
      .abas{
        margin: 88px 0px 32px 0px; width: 100%; min-width: 320px;
        height: 48px; display: flex; /*flex-direction: row;*/ justify-content: space-around; align-items: center;
        background-color: #24272E; border-radius: 8px; color: #fff;
      }
      .nomeSecao {font-size: 20px;}
    }


    .secao{
        display: flex; flex-direction: column; position: relative;
        width: -webkit-fill-available; height: auto; /*padding-top: 8px; padding-bottom: 4px;*/
        background: rgba(255,255,255, 0.1) ; box-sizing: border-box; margin-top: 16px;
        border-radius: 16px;  user-select: none; /*border: 1px solid rgba(254,236,110,0.1);*/
       
      }
      .secaotop{
        display: flex; flex-direction: column; position: relative;
        width: -webkit-fill-available; height: auto;
        background: linear-gradient(to right, #718001, #191919 ) ; box-sizing: border-box;
        border-radius: 16px; margin-bottom: 8px; user-select: none; /*border: 1px solid rgba(254,236,110,0.1);*/
       
      }


    .tattoo{
      display: flex; flex-direction: row; position: relative;
      width: -webkit-fill-available; height: auto;
      background-color: rgba(255,255,255, 0.1); box-sizing: border-box;
      border-radius: 16px; margin-bottom: 16px;
  }

  .hidden{
    visibility: hidden;
  }

  .none{
    display: none;
  }

        .sParte1{
            display: flex; flex-direction: row; justify-content: space-between;
            width: -webkit-fill-available; height: 50px; align-items: center;
            padding: 0 8px 0 0px; color: #Fff; font-weight: 700; font-size: 18px;
            /*border: #fff solid 1px;*/
        }

        .tParte1{
          display: flex; flex-direction: row; justify-content: space-between;
          width: -webkit-fill-available; height: 40; align-items: center;
          padding: 0 8px 0 16px; color: #Fff; font-weight: 400; font-size: 16px;
      }

            .sNome{
                 cursor: pointer; display: flex; flex-direction: row; align-items: center; gap: 8px; text-align: left; justify-content: space-between;
                 width: 100%; outline: none;  -webkit-tap-highlight-color: transparent;
            }

            .sNomeT{
              cursor: pointer; display: flex; flex-direction: row; align-items: center; gap: 8px; text-align: left; 
              width: 100%; outline: none;  -webkit-tap-highlight-color: transparent;
         }

         .sNomeT .sNome:active{
              cursor: pointer; display: flex; flex-direction: row; align-items: center; gap: 8px; text-align: left; justify-content: space-between;
              width: 100%; background: linear-gradient(to right, rgba(223,252,1,0),rgba(223,252,1,5),rgba(223,252,1,0)); transition: 2s ease-in-out;
         }
            .sNome:focus{
              outline: none;
            }

            .iconNS{
                font-size: 24px; color: #dffc01; 
                border-radius: 16px; 
            }

            .iconNT{
              font-size: 16px; color: #dffc01; margin: 0px auto; 
          }

            .iconSTA{
                font-size: 20px; color: #FFF;
            }
            .iconSTD{
              font-size: 20px; color: #dffc01;
          }


            .sSettings{
                display: flex; flex-direction: row; gap: 24px; margin-left: 24px;
            }

            .toggleInON{
                width: 16px; height: 16px; margin-left: 4px; margin-right: 4px;
                background-color: #03CC00; border-radius: 100%;
            }
            .toggleInOff{
              width: 16px; height: 16px; margin-left: 4px; margin-right: 4px;
              background-color: white; border-radius: 100%;
          }

          .toggleIn{
            width: 16px; height: 16px; margin-left: 4px; margin-right: 4px;
            background-color: white; border-radius: 100%;
        }

    
            .toggleOffOut{
                display: flex; flex-direction: row; justify-content: space-between; align-items: center;
                width: 56px; height: 24px; 
                background-color: rgba(255, 255, 255, 0.2); border-radius: 30px;
            }
    
            .toggleOffText{
                font-size: 11px; color: white; font-weight: 100; margin-right: 8px; user-select: none;
            }
    
            .toggleOnOut{
               display: flex; flex-direction: row; justify-content: space-between; align-items: center;
                 width: 56px; height: 24px; border-radius: 30px;
                 background: rgba(3,204,0,0.25); 
            }
    
            .toggleOnText{
                font-size: 13px; color: #Fff; font-weight: 400; margin-left: 8px; user-select: none;
            }
    





        .sParte2{
            display: flex; flex-direction: row; justify-content: center; align-items: center;
            width: -webkit-fill-available; height: 48px; gap: 32px;
            /*background: linear-gradient(to top, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0) ) ;*/
            color: #9c9c9c; font-weight: 400; font-size: 18px;
            border-radius: 0px 0px 16px 16px; 
        }

        .ordem{
          font-size: 12px; margin: -8px;
        }

        .tParte2{
          display: flex; flex-direction: column; justify-content: space-around; align-items: center;
          width: 32px; 
          background-color: rgba(255, 255, 255, 0.2);
          color: #Fff; font-weight: 400; font-size: 18px;
          border-radius: 16px 0px 0px 16px; padding: 4px 0px;
      }

.sConteudo{
  display: block; padding: 16px;
  

}

.capaTattoo{
  width: 56px; height: 66px; border-radius: 4px;margin: 8px 16px 8px 0px;
}



/* ImageCropper.css */
.cropper-container {
  position: relative;
  width: 100%;
  height: 400px; /* Ajuste a altura conforme necessário */
}
.cropper-crop-box {
  width: 70%; /* Ajuste a largura conforme necessário */
  height: 70%; /* Ajuste a altura conforme necessário */
}
/*  className="ct"  */
.ct{
  z-index: 50;
}


.cardTopEmpty{
  width: 73px; height: 92px; display: flex; flex-direction: column; justify-content: center; align-items: center;
  background: rgba(0,0,0,0.8); border-radius: 6px; border: 2px solid #dffc01;
  color: #191919;
}

.cardTopSet{
  width: 73px; height: 92px; display: flex; flex-direction: column; justify-content: space-around; align-items: center;
  background: #443E1E; border-radius: 8px; border: 2px solid #dffc01;
  color: #191919; position: relative; 
}

.cardTopSet2{
  width: 73px; height: 92px; display: flex; flex-direction: column; justify-content: space-around; align-items: center;
  background: #443E1E; border-radius: 8px; 
  color: #997E00; position: relative; 
}

.top{
  display: inline-flex; flex-wrap: wrap; flex-direction: row; gap: 8px;
  justify-content: left; margin: 24px 12px;
}

.tTop{
  display: flex; flex-direction: row; justify-content: center; align-items: center;
  position: absolute; bottom: 0; border-radius: 0px 0px 4px 4px;
  width: 73px; height: 24px; background:#dffc01 ;
  font-size: 16px; color: #191919;font-weight: 700; 
}

.resetTop{
  position: absolute; top: 0; right: 0;
}

.capaTop1{
  width: 73px; height: 92px; display: flex; flex-direction: column; justify-content: space-around; align-items: center;
  background: #ffffff; border-radius: 10px; 
  opacity: 1;
  
}

.capaTop.loaded {
  width: 73px; height: 92px; display: flex; flex-direction: column; justify-content: space-around; align-items: center;
  background: #ffffff; border-radius: 4px; 
  opacity: 1;
}

.selectTop{
  width: 73px; height: 92px; display: flex; flex-direction: column; justify-content: center; align-items: center;
  border-radius: 6px; border: 2px solid #dffc01;
  color: #fff; position: absolute; background: rgba(0,0,0,0.3);
}

.results{
  display: inline-flex; flex-direction: row; flex-wrap: wrap; gap: 8px;
  max-height: 322px; padding: 8px;
  overflow-y: auto;
}

.DelConta{
  display: flex; flex-direction: row; color: rgb(255, 92, 92);
  background-color: rgba(255, 114, 114, 0.1);
  width: 160px; height: 45px; border-radius: 16px;
  align-items: center; justify-content: center; gap: 4px; cursor: pointer;
}

.clientes-container{
  display: flex; flex-direction: column; justify-content: center; align-items: center;
}
.clientes-box{
  width: 1200px; height: 800px;
  background-color: rgba(255,255,255, 0.1);
  border-radius: 16px; margin-top: 48px;
}

.clientes-tabela {
  width: 100%;
  border-collapse: collapse;
}

.clientes-tabela th, .clientes-tabela td {
  border: 1px solid rgba(223,252,1,0.5);
  padding: 8px;
  text-align: left; user-select: none;
}

.clientes-tabela th {
  background-color: rgba(223,252,1,0.1);
  font-weight: bold;
}

.clientes-tabela tr {
 
  color: #fff;
}

.clientes-tabela tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.1); /* Cor de fundo para linhas ímpares */
}

.clientes-tabela tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.2); /* Cor de fundo para linhas pares */
}.clientes-tabela tr:hover {
  background-color: rgba(223,252,1,0.1); /* Cor de destaque ao passar o mouse */
}

.red-text {
  color: red; background-color: #03CC00;/* Muda a cor do texto para vermelho */
}