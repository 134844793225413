/*Reatc*/

.bgAcaoM{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: -webkit-fill-available;  height: 100vh; 
    background-color: rgba(0,0,0, 0.9);
    backdrop-filter: blur(10px);
     
    display: flex; 
    /*flex-direction: column;*/
    justify-content: center; 
    align-items: start;
    box-sizing: border-box; 
    z-index: 1000;
    overflow: auto;
    padding-top: 48px;
     
     
    
    user-select: none
}

.bgAcao{
   
    position: fixed;
    top: 0;
    left: 0;
    width: -webkit-fill-available;  height: -webkit-fill-available; 
    background-color: rgba(0,0,0, 0.9);
    backdrop-filter: blur(10px);
     
    display: flex; 
    /*flex-direction: column;*/
    justify-content: center; 
    align-items: center;
    box-sizing: border-box; 
    z-index: 1000;
    overflow: hidden;
    padding-top: 48px;
    box-sizing: border-box;
     
     
    
    user-select: none
}

.bgAcaotop{
   
    position: fixed;
    top: 0;
    left: 0;
    width: -webkit-fill-available;  height: 100vh; 
    background-color: rgba(0,0,0, 0.9);
    backdrop-filter: blur(10px);
     
    display: flex; 
    /*flex-direction: column;*/
    justify-content: center; 
    align-items: start;
    box-sizing: border-box; 
    z-index: 1000;
    overflow: hidden;
    padding-top: 48px;
    box-sizing: border-box;
     
     
    
    user-select: none
}



.nameAction{
    font-size: 18px; font-weight: 700;
    height: 40px;  background: linear-gradient(to right, #718001, #191919); border-radius: 16px 16px 0px 0px;
    display: flex; align-items: center; flex-direction: row; padding-left: 16px; gap: 8px;
    color: #fff;
}
 


.iconAction{
    font-size: 24px; margin-left: 16px; margin-right: 8px;

}

.contCard{
    font-size: 16px; font-weight: 400; padding: 16px; display: flex; flex-direction: column;
    color: white;
}



.input{
    height: 24px; padding-left: 16px; font-size: 16px; margin-bottom: 16px; margin-top: 8px;
     width: -webkit-fill-available; border-radius: 8px; border: 0px none;
     background: rgba(255,255,255,0.15); color: white; font-family: 'montserrat', sans-serif;
}

.input:focus{
  
    border-color: #00BF60 !important;
}

.inputTam{
    height: 48px; padding: 0 16px; font-size: 24px;  margin-bottom: 16px; margin-top: 8px;
    font-family: 'montserrat', sans-serif; width: 56px; border-radius: 8px; border: 0px none; background: rgba(255,255,255,0.15); color: white;
}

.inputPreco{
    height: 16px; padding-left: 16px; font-size: 16px; margin-bottom: 16px; margin-top: 8px;
    font-family: 'montserrat', sans-serif; width: 50%; border-radius: 8px; border: 0px none;background: rgba(255,255,255,0.15); color: white;
}

.contadorDesc{
    width: 100%;
    display: flex; flex-direction: row; justify-content: end; margin-top: -8px;
}

.inputTextArea{
    height: 100px; padding: 16px; font-size: 14px; margin-bottom: 16px; margin-top: 8px;
     width: -webkit-fill-available; border-radius: 8px; border: 0px none;
    font-family: 'montserrat', sans-serif; background: rgba(255,255,255,0.15); color: white;
}

.btCardConfirm{
 width: 100px; height: 48px; font-size: 16px; font-weight: 700;
 border: 0px none; border-radius: 16px;
 background: linear-gradient(to bottom, #718001 60%, #dffc01 130%); 
 cursor: pointer; border: #dffc01 solid 1px; padding: 0; color:#fff  ;
}

.btCardConfirm:disabled{
    width: 100px; height: 48px; font-size: 16px; font-weight: 700;
    border: 0px none; border-radius: 16px;
    background: linear-gradient(to bottom, #718001 60%, #dffc01 130%); 
    cursor: pointer; border: #dffc01 solid 1px; opacity: 0.3;
   }



.brCardCancel{
    width: 100px; height: 48px; font-size: 16px; 
     border-radius: 16px;
    color: #dffc01;
  background: transparent; border: 1px solid #dffc01;  padding: 0;
}



.botoesCard{
    display: inline-flex; flex-direction: row; justify-content: end; gap: 16px;
}

.disabled-button {
    opacity: 0.3; 
}


.cardAddSecao{
    display: flex; flex-direction: column;  width: 90%;
    min-width: 339px; max-width: 482px; 
    background: #191919; border: 2px solid #dffc01;
     box-sizing: border-box; border-radius:16px; 
}

.cardAddSecao2{
    display: flex; flex-direction: column;  width: 90%;
    min-width: 339px; max-width: 482px; 
    background: #191919; border: 2px solid #dffc01;
     box-sizing: border-box; border-radius:16px; overflow: none;
     margin: 0px auto 24px auto; 
}


.cardAddSecaoDel{
    display: flex; flex-direction: column; margin: auto; width: 90%;
    min-width: 339px; max-width: 482px; height: auto; background-color: #191919;
     box-sizing: border-box; border-radius: 8px; border: 1px solid #FF6767;
     position: fixed; top: 50%; left: 50%; transform: translate(-50%,-50%);
}

.cardAddTattoo{
    display: flex; flex-direction: column; margin: auto; width: 90%;
    min-width: 339px; max-width: 482px; height: 562px; background-color: #D9D9D9;
     box-sizing: border-box; border-radius: 8px; border: 1px solid #997E00;
}

.labelToggle{
    display: flex; gap: 16px; flex-direction: row; color: white;
}

.TamPreco{
    display: flex; flex-direction: row; width: 100%; 
    margin-top: 32px;
}

.alCol{
    display: block; flex: 1;
}

.alRowF{
    display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 32px 0px;
}

.col{
    display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 8px;
    font-size: 12px; color: #fff;
}

.iconPhoto{
    font-size: 24px; color: #dffc01;
}

.iconX{
    font-size: 20px;
      color: #fff;
}

.bgIX{
    background-color: rgba(0, 0, 0, 0.5);
    width: 24px; height: 24px; position: absolute; top: 0; right: 0;
    display: flex; justify-content: center; align-items: center;
}
.photoEmpty{
    display: flex; justify-content: center;
    height: 92px; width: 73px; border: 1px solid #dffc01;
    border-radius: 8px; background: #3B413A; 
}

@media only screen and (max-width: 375px) {
    .photoEmpty{
        display: flex; justify-content: center;
        height: 75px; width: 60px; border: 1px solid #dffc01;
        border-radius: 8px; background: #3B413A; 
    } 

    .col{
        display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 8px;
        font-size: 10px; color: #fff;
    }

    .cardAddSecao2{
        display: flex; flex-direction: column;  width: 300px;
         
        background: #191919; border: 2px solid #dffc01;
         box-sizing: border-box; border-radius:16px; overflow: none;
        margin-left: -24px;
    }

}

.photoX{
    display: flex;
    position: relative;
    height: 92px; width: 73px; 
    border-radius: 8px;
}

.inputPhoto{
    display: none;
}

.inactive{
    opacity: 0.3;
}

.linhaTam{
    display: flex; flex-direction: row; align-items: center; gap: 8px;
}

.toggleOND, .toggleONT, .toggleONP{
    display: none; flex-direction: row; align-items: center; justify-content: end;
    width: 40px; height: 22px; background-color: #00BF60; border-radius: 18px;
}

.toggleOFFD, .toggleOFFT, .toggleOFFP{
    display: flex; flex-direction: row; align-items: center; justify-content: start;
    width: 40px; height: 22px; background-color: #b3b3b3; border-radius: 18px;
}

/*Settings */

.boxSet{
    display: flex; flex-direction: column;
    width: 70%; height: auto; background-color: #000;
    border-radius: 8px 0px 8px 8px; max-width: 300px;
    position: absolute; right: 0; top: 0; margin-top: 16px; margin-right: 40px;
    font-size: 16px; color: #fff; border: 2px solid rgba(254,236,110,0.3); z-index: 1000;
}

.setEdit{
    display: inline-flex; flex-direction: row; align-items: center;
    width: -webkit-fill-available; height: 48px; gap: 16px; padding-left: 16px; font-weight: 600;
}

.setDel{
    display: inline-flex; flex-direction: row; align-items: center;
    width: -webkit-fill-available; height: 48px; gap: 16px; padding-left: 16px; font-weight: 600;
    color: #FF6767;
}

.iconSet{
    font-size: 24px;
}




/*Delete Card */

.delAction{
    font-size: 18px; font-weight: 700; padding-left: 16px; gap: 8px;
    height: 40px; background-color: #441E1E; border-radius: 8px 8px 0px 0px;
    display: inline-flex; flex-direction: row; align-items: center; color: #FF6767;
}

    .contCardDel{
        text-align: center; font-size: 16px; font-weight: 400; padding: 16px; display: flex; flex-direction: column; color: #fff;
    }

    .nomeSDel{
        font-size: 16px; font-weight: 700; margin-bottom: 16px;
    }

    .botoesCardDel{
        display: inline-flex; flex-direction: row; justify-content: center; gap: 16px;
    }

        .btCardConfirmDel{
            width: 144px; height: 40px; font-size: 16px; 
            border: 0px none; border-radius: 16px;
            background-color: #FF6767; color: #fff; display: flex;
            flex-direction: row; justify-content: center; align-items: center;
        }
        
        .brCardCancelDel{
            width: 144px; height: 40px; font-size: 16px; 
            border: 1px solid #fff; border-radius: 16px;
            background: transparent; color: #fff; display: flex;
            flex-direction: row; justify-content: center; align-items: center;
        }


        .tattooZero{
            color: rgba(255,255,255,0.5); margin-top: 24px; font-size: 14px;
        }

.upload{
    display: flex; flex-direction: row; justify-content: center; align-items: center; padding: 0;
    width: 150px; height: 48px; background-color: #fff; color: #000000;
}

.crop{
    background-color:#7d0000; width: 243px; height: 324px;
}

.image{
    
   background-color:#e0e0e0; width: 243px; height: 324px;
    
}

.img{
    display: flex; flex-direction: row; gap: 32px;
}

.cortar{
    z-index: 50; width: 100%; height: 48px;
}

.d-flex{
  display: flex;
}
.d-none{
    display: none;
  }

#box {
    background-color: #efefef;
    border: solid 1px #3e3e3e;
    padding: 10px;
  }
  
  #box-crop {
    display: flex;
  }
  
  #preview-crop {
    display: none;
    background-color: #efefef;
    border: solid 2px red;
    width: 200px;
    height: 200px;
    margin-right: 10px;
    overflow: hidden;
  }


  

  /*.areaCrop{
    background: "#fff";
    position: "relative";
    height: 300;
    width: 600;
    minWidth: { sm: 500 };
  }*/

  .result{
    width: 400px; height: 500px; 
  }

.ph{
    display: none;
}


  /*   
  <Box>
                <Typography>Zoom: {zoomPercent(zoom)}</Typography>
                <Slider
                valueLabelDisplay='auto'
                valueLabelFormat={zoomPercent}
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom)=> setZoom(zoom)}
                />
            </Box>
            <Box>
                <Typography>Rotation: {rotation}</Typography>
                <Slider
                valueLabelDisplay='auto'
               
                min={0}
                max={360}
                
                value={rotation}
                onChange={(e, rotation)=> setRotation(rotation)}
                />
            </Box>

  */