
#pg{
    /*position: fixed;
    top: 0;
  left: 0;*/
  width: 100%;  /* Ocupa toda a largura */
  height: 100vh;  /* Ocupa toda a altura */
  z-index: 0; opacity: 0; 
  transition: opacity 0.5s ease; 
  animation: fadePg 1s ease-in forwards; position: relative;
    
 display: flex; flex-direction: column;  box-sizing: border-box;
 
}


.logobio{ width: 100vw; z-index: 6;
    position: fixed;
    bottom: 2%;
  
color: #fff;
}
.transitionBefore{
    position: fixed;
    top: 0;
  left: 0;
  width: 100%;  /* Ocupa toda a largura */
  height: 100%;  /* Ocupa toda a altura */
  z-index: -1; opacity: 0;
 
  animation: fadePg 0.5s ease-in forwards;
    
 display: flex; flex-direction: column; 
 
}
.fontRoboto{ font-family: 'Roboto' !important;}
.fontSlab{ font-family: 'Roboto slab'!important;}
.fontSerif{ font-family: 'Roboto serif' !important;}
.fontAlegreya{ font-family: 'Alegreya' !important;}

#pgW{
    position: fixed;
    top: 0;
  left: 0;
  width: 100%;  /* Ocupa toda a largura */
  height: 100%;  /* Ocupa toda a altura */
  z-index: 1; 
  user-select: none;
  transition: opacity 1s ease-in;
    
 display: flex; flex-direction: column; 
 
}

#pgW2{
    position: fixed;
    top: 0;
  left: 0;
  width: 100%;  /* Ocupa toda a largura */
  height: 100%;  /* Ocupa toda a altura */
   opacity: 1;
  

    
 display: flex; flex-direction: column; 
 
}

/* Adicione esta classe ao seu arquivo CSS */
.fade-out {
    animation: fadeOut 1s forwards;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  



@keyframes fadePg {
     0% {opacity: 0;} 
    100% {opacity: 1;}
}


.p1{
      margin-top: 7%; box-sizing: border-box;
    position: relative; overflow: hidden; height: auto; 
    display: flex; flex-direction: column;  align-items: center; justify-content:center;
}






.bioline1{
    display: flex; flex-direction: row; height: 140px;
   
   scrollbar-width: none; -ms-overflow-style: none;

  
  
    white-space: nowrap; gap: 8px; opacity: 0;
    animation: 45s slideR infinite linear,
    fadeCarrossel1 1s ease-in-out;
   animation-delay: 1.5s ; animation-fill-mode: forwards;
   
    
    &::-webkit-scrollbar { display: none;}
}

.bioline2{
    display: flex; flex-direction: row; height: 140px;
    
   scrollbar-width: none; -ms-overflow-style: none;
   
    white-space: nowrap; gap: 8px; margin-top: 8px; opacity: 0;
    animation: 40s slideL infinite linear,
     fadeCarrossel2 1s ease-in-out;
    animation-delay: 1.5s ; animation-fill-mode: forwards;
    

    &::-webkit-scrollbar { display: none;}

    

    
}

.bioline3{
    display: flex; flex-direction: row; height: 140px;
   
   scrollbar-width: none; -ms-overflow-style: none;
margin-top: 8px;
  
  
    white-space: nowrap; gap: 8px; opacity: 0;
    animation: 35s slideR infinite linear,
    fadeCarrossel3 1s ease-in-out;
   animation-delay: 1.5s ; animation-fill-mode: forwards;
   
    
    &::-webkit-scrollbar { display: none;}
}


@keyframes fadeCarrossel1 {
    0% {opacity: 0; } 
    100% {opacity: 1;}
}
@keyframes fadeCarrossel2 {
    0% {opacity: 0; } 
    100% {opacity: 0.7;}
}
@keyframes fadeCarrossel3 {
    0% {opacity: 0; } 
    100% {opacity: 0.7;}
}


@keyframes slideL{
    from{
        transform: translateX(00%);
    }
    to{
        transform: translateX(-80%);
    }
}
@keyframes slideR{
    from{
        transform: translateX(00%);
    }
    to{
        transform: translateX(-80%);
    }
}

.dont{
    position: fixed; top: 50%; left: 50%; transform: translate(-50%,-50%);
    display: flex; justify-content: center; align-items: center;
    color: #fff;
}

.p2{
    margin-left: 16px; margin-right: 16px; margin-bottom: 48px; z-index: 3; box-sizing: border-box;
    display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: -60px;
}

.photo{
    width: auto; height: 140px; border-radius: 16px; 
     /* Ajuste conforme necessário */
   
}


.perfilB{
    
    width: 200px; height: 200px; 
    position: absolute; z-index: 4; 
    top: 60%;
    left: 50%;
    transform: translate(-60%, -50%);
    border-radius: 100%; opacity: 0;
    display: flex; justify-content: center; align-items: center;
    animation-name: perfil; animation-duration: 1s;  
    animation-delay: 1s; animation-fill-mode: forwards;
    
    
}

@media(max-width: 376px){
    .p2{
        margin-left: 16px; margin-right: 16px; margin-bottom: 48px; z-index: 3; box-sizing: border-box;
        display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: -130px;
    }
    .perfilB{
    
        width: 200px; height: 200px; 
        position: absolute; z-index: 4; 
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%; opacity: 0;
        display: flex; justify-content: center; align-items: center;
        animation-name: perfil; animation-duration: 1s;  
        animation-delay: 1s; animation-fill-mode: forwards;
        
        
    }
}



.perfilP{
    width: 190px; height: 190px; border-radius: 100%;
    box-shadow: 0px 0px 24px black; position: relative;
}


.perfilPReflex{
    width: 100%; height: 100%; 
    position: absolute; z-index: 10; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   
}

.nome{
    font-size: 38px; color: #fff;  z-index: 4;
   margin: 24px auto; font-weight: 700; 
   animation: nome 1s ease-in-out;  transform: scale(0);
    animation-delay: 1s ; animation-fill-mode: forwards;
 
}

@keyframes nome{
    0% {
        transform: scale(0);  /* Tamanho inicial */
      }
      
      100% {
        transform: scale(1);  /* Retornar ao tamanho inicial */
      }
}

.perfil{
    position: absolute; z-index: 3; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; 
    
    display: flex; flex-direction: column; 
}

@keyframes animacaoEscala {
    0% {
      transform: scale(1);  /* Tamanho inicial */
    }
    50% {
      transform: scale(1.03);  /* Tamanho ampliado */
    }
    100% {
      transform: scale(1);  /* Retornar ao tamanho inicial */
    }
  }

.btCat{
    display: flex; flex-direction: row; justify-content: center;
    height: 56px; width: 80%; align-items: center; gap: 8px;
    background: rgba(255,255,255,0.3); border-radius: 56px;
    overflow: hidden; position: relative; margin-bottom: 16px;
    max-width: 400px;  transform: translate(0, 50px); /* Estado inicial */
    background-size: 400% 200%; transition: background 5s ease, box-shadow 0.5s;
    animation: brilho 4.4s cubic-bezier(0.2, 0.5, 0.9, 0.6) 0s infinite,
    fadeBtTT 0.7s ease-in 0.6s both, bts 1s ease-in-out 1.6s both, 
    animacaoEscala 6s infinite ease-in-out 2.5s, forwards;
    cursor: pointer;user-select: none; -webkit-tap-highlight-color: transparent;
    
}

@keyframes brilho {0% {background-position: 100% 0;} 100% {background-position: 0 0;}}

.btWhats{
    display: flex; flex-direction: row; justify-content: space-between;
    height: 64px; width: 90%; align-items: center;
    background: linear-gradient(90deg, #4AB855, #004707);
    border-radius: 16px; 
    overflow: hidden; position: relative; margin-bottom: 16px;
    max-width: 400px; cursor: pointer; user-select: none;
}
.btWhats1{
    display: flex; flex-direction: row; justify-content: center; box-sizing: border-box;
    height: 64px; width: 64px; align-items: center; 
    background: #004707; border: 2px solid #2CFA41; 
    border-radius: 56px; gap: 24px; z-index: 50;
    overflow: hidden; position: fixed; bottom: 2%; right: 24px; margin-bottom: 16px;
    max-width: 400px;  transform: translate(0, 0px); /* Estado inicial */
    animation: bts 1s ease-in-out 1.8s both; cursor: pointer; user-select: none; -webkit-tap-highlight-color: transparent;
}

.btSaiba{
    display: flex; flex-direction: row; justify-content: space-between;
    height: 64px; width: 70%; align-items: center;
    background: linear-gradient(90deg, rgba(127,127,127,0.7), rgba(127,127,127,0.2));
    border-radius: 16px;
    overflow: hidden; position: relative; margin-bottom: 16px;
    max-width: 400px; cursor: pointer;
}

.btSaiba1{
    display: flex; flex-direction: row; justify-content: center;
    height: 40px; width: 70%; align-items: center; gap: 8px;
    background:  rgba(255,255,255,0.2); /*border: 3px solid #CCCCCC ;*/
    border-radius: 56px;
    overflow: hidden; position: relative; margin-bottom: 16px;
    max-width: 400px;  transform: translate(0, 50px); /* Estado inicial */
    animation: bts 1s ease-in-out 2s both; cursor: pointer; user-select: none; -webkit-tap-highlight-color: transparent;

}


@keyframes bts{
    from{transform:  translate(0,50px);
    opacity: 0;}
    to{transform:  translate(0,0);
    opacity: 1;}
}

.cta{ 
  font-size: 20px; color: #fff; font-weight: 700; 
}


.ctaSub{
    font-size: 12px; color: #fff;  
  }

.iconBT{
    width: 100px; height: 100px; background: #fff; border-radius: 100%;
     margin-right: -24px; 
     position: relative; 
}



.iconBTW{
    width: 100px; height: 100px; background: #fff; border-radius: 100%;
     margin-right: -40px; border: 12px outset #4AB855;
     position: relative; 
}

.iconBTW1{
    width: 100px; height: 100px; 
    background:  /*#16A124*/ rgba(255,255,255,0.1);
     border-radius: 100%;
     margin-right: -30px; 
     position: relative; 
}

.iconBTS{
    width: 100px; height: 100px; background: #fff; border-radius: 100%;
     margin-right: -40px; border: 12px outset rgba(127,127,127,0.5);
     position: relative; 
}
.iconBTS1{
    width: 100px; height: 100px; background: #fff; border-radius: 100%;
    background: rgba(255,255,255,0.1);
    margin-right: -30px; 
     position: relative; 
}


.iconC{
    font-size: 40px; 
}

.iconW{
    font-size: 40px; color: #4AB855; position: absolute; right: 0; margin-right: 16px;
}
.iconW1{
    font-size: 40px; color: #fff; position: absolute; right: 0; margin-right: 12px;
    
}

.iconS{
    font-size: 40px; color: #7F7F7F; position: absolute; right: 0; margin-right: 16px;
}

.iconS1{
    font-size: 40px; color: #fff; position: absolute; right: 0; margin-right: 16px;
}

.txtCta{
    display: flex; flex-direction: column; justify-content: center; align-items: center; 
    
}


/*Temas*/
/*.btAmarelo{
    background: linear-gradient(90deg, #E5BF00,#473B00);
}*/



.iAmarelo{
    color: #fff;
}

.btVermelho{
    background-image: linear-gradient(70deg,
    #680000, #CC0101, #680000, #CC0101);
    border: 2px solid #FF0000;
}
.btLaranja{
    background-image: linear-gradient(70deg,
    #683400, #CC6601, #683400, #CC6601);
    border: 2px solid #FF8000;
}
.btAmarelo{
    background-image: linear-gradient(70deg,
    #685700, #CCAA01, #685700, #CCAA01);
    border: 2px solid #FFD400;
}
.btVerde{
    background-image: linear-gradient(70deg,
    #01CC01, #006800, #01CC01, #006800);
    border: 2px solid #00FF00;
}
.btCiano{
    background-image: linear-gradient(70deg,
    #01CCCC, #006868, #01CCCC, #006868);
    border: 2px solid #00FFFF;
}
.btAzul{
    background-image: linear-gradient(70deg,
    #003468, #0166CC, #003468, #0166CC);
    border: 2px solid #0080FF;
}
.btRoxo{
    background-image: linear-gradient(70deg,
    #460068, #8801CC, #460068, #8801CC);
    border: 2px solid #AA00FF;
}
.btRosa{
    background-image: linear-gradient(70deg,
    #680057, #CC01AA, #680057, #CC01AA);
    border: 2px solid #FF00D4;
}
.btMagenta{
    background-image: linear-gradient(70deg,
    #680023, #CC0145, #680023, #CC0145);
    border: 2px solid #FF0055;
}
.btBranco{
    background-image: linear-gradient(70deg,
    #686868, #B3B3B3, #686868, #B3B3B3);
    border: 2px solid #CCCCCC;
}



.biVermelho{ background: #FF0000 ; }
.biLaranja{ background: #FF8000 ; }
.biAmarelo{ background: #FFD400 ; }
.biVerde{ background: #00FF00 ; }
.biCiano{ background: #00FFFF; }
.biAzul{ background: #0080FF ; }
.biRoxo{ background: #AA00FF ; }
.biRosa{ background: #FF00D4 ; }
.biMagenta{ background: #FF0055 ; }
.biBranco{ background: #CCCCCC ; }

.biAmarelo1{
    background: #E3BD00 ;
    color: #E3BD00; 
}

.iAmarelo1{
    color: #fff;
    
   
}

/*.cAmarelo{
    background: linear-gradient(to right, #FFEA7F, #FFD400);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
}*/

.tx{
    font-size: 20px; font-weight: 700; margin-left: 16px;
    -webkit-background-clip: text;
    
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

.txVermelho{ 
    color:  #FF0000;
}
.txLaranja{ 
    color:  #FF8000; 
}
.txAmarelo{ 
    color:  #FFD400;
}
.txVerde{ 
    color:  #00FF00;
}
.txCiano{ 
    color:  #00FFFF;
}
.txAzul{ 
    color:  #0080FF;
}
.txRoxo{ 
    color:  #AA00FF;
}
.txRosa{ 
    color:  #FF00D4;
}
.txMagenta{ 
    color:  #FF0055;
}
.txBranco{ 
    color:  #CCCCCC;
}


.cWhats{
    font-size: 20px;  font-weight: 700; 
    background: linear-gradient(to right, #7EFF8B, #2BF63F);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
}

/* Borda Perfil */

.bordaVermelho{ border: 2px solid #FF0000;}
.bordaLaranja{ border: 2px solid #FF8000;}
.bordaAmarelo{ border: 2px solid #FFD400;}
.bordaVerde{ border: 2px solid #00FF00;}
.bordaCiano{ border: 2px solid #00FFFF;}
.bordaAzul{ border: 2px solid #0080FF;}
.bordaRoxo{ border: 2px solid #AA00FF;}
.bordaRosa{ border: 2px solid #FF00D4;}
.bordaMagenta{ border: 2px solid #FF0055;}
.bordaBranco{ border: 2px solid #CCCCCC;}

/* Background */

.pgVermelho{ background-color: #190000;}
.pgLaranja{ background-color: #190D00;}
.pgAmarelo{ background-color: #191500;}
.pgVerde{ background-color: #001900;}
.pgCiano{ background-color: #001919;}
.pgAzul{ background-color: #000D19;}
.pgRoxo{ background-color: #110019;}
.pgRosa{ background-color: #190015;}
.pgMagenta{ background-color: #190008;}
.pgBranco{ background-color: #191919;}


/* Fade Carrossel */
.fade{
    width: 100%; height: 100%; position: absolute; top: 0; left: 0; 
}

.fade2{
    width: 100%; height: 100%; position: absolute; bottom: 0; left: 0; 
}
.fdVermelho{ background: linear-gradient(90deg,
rgba(25,0,0,1)5%,rgba(25,0,0,0.2)50%,rgba(25,0,0,1)95% );}
.fdLaranja{ background: linear-gradient(90deg,
    rgba(25,13,0,1)5%,rgba(25,13,0,0.2)50%,rgba(25,13,0,1)95% );}
.fdAmarelo{  background: linear-gradient(90deg,
     rgba(25,21,0,1)5%,rgba(25,21,0,0.2)50%,rgba(25,21,0,1)95% );}
.fdVerde{ background: linear-gradient(90deg,
    rgba(0,25,0,1)5%,rgba(0,25,0,0.2)50%,rgba(0,25,0,1)95% );}
.fdCiano{  background: linear-gradient(90deg,
    rgba(0,25,25,1)5%,rgba(0,25,25,0.2)50%,rgba(0,25,25,1)95% );}
.fdAzul{ background: linear-gradient(90deg,
    rgba(0,13,25,1)5%,rgba(0,13,25,0.2)50%,rgba(0,13,25,1)95% );}
.fdRoxo{  background: linear-gradient(90deg,
    rgba(17,0,25,1)5%,rgba(17,0,25,0.2)50%,rgba(17,0,25,1)95% );}
.fdRosa{  background: linear-gradient(90deg,
    rgba(25,0,21,1)5%,rgba(25,0,21,0.2)50%,rgba(25,0,21,1)95% );}
.fdMagenta{ background: linear-gradient(90deg,
    rgba(25,0,8,1)5%,rgba(25,0,8,0.2)50%,rgba(25,0,8,1)95% );}
.fdBranco{  background: linear-gradient(90deg,
    rgba(25,25,25,1)5%,rgba(25,25,25,0.2)50%,rgba(25,25,25,1)95% );}

    .fdBranco2{  background: linear-gradient(0deg,
        rgba(25,25,25,1)5%,rgba(25,25,25,0.2)30% );}
    
    .fdVermelho2{ background: linear-gradient(0deg,
rgba(25,0,0,1)5%,rgba(25,0,0,0.2)30% );}
.fdLaranja2{ background: linear-gradient(0deg,
    rgba(25,13,0,1)5%,rgba(25,13,0,0.2)30% );}
.fdAmarelo2{  background: linear-gradient(0deg,
     rgba(25,21,0,1)5%,rgba(25,21,0,0.2)30% );}
.fdVerde2{ background: linear-gradient(0deg,
    rgba(0,25,0,1)5%,rgba(0,25,0,0.2)30% );}
.fdCiano2{  background: linear-gradient(0deg,
    rgba(0,25,25,1)5%,rgba(0,25,25,0.2)30% );}
.fdAzul2{ background: linear-gradient(0deg,
    rgba(0,13,25,1)5%,rgba(0,13,25,0.2)30% );}
.fdRoxo2{  background: linear-gradient(0deg,
    rgba(17,0,25,1)5%,rgba(17,0,25,0.2)30% );}
.fdRosa2{  background: linear-gradient(0deg,
    rgba(25,0,21,1)5%,rgba(25,0,21,0.2)30%);}
.fdMagenta2{ background: linear-gradient(0deg,
    rgba(25,0,8,1)5%,rgba(25,0,8,0.2)30% );}

.bordaPP{
    display: flex; align-items: center; justify-content: center;
    width: 240px; height: 240px; 
    position: absolute; opacity: 0;
    animation: bordaP 1s ease-in-out;  
         animation-delay: 1.1s ; animation-fill-mode: forwards;
    
}

.glifocentral{
    
    height:100%; width: 100%;
}

@keyframes bordaP {
    0% {opacity: 0; transform: rotate(-45deg) scale(0.5);} 
    100% {opacity: 0.5;transform: rotate(0deg) scale(1);}
}


@keyframes perfil {
    0% {opacity: 0; transform: translate(-50%, -50%) rotate(-45deg) scale(0);} 
    100% {opacity: 1;transform: translate(-50%, -50%) rotate(0deg) scale(1);}
}




/*@media(min-width: 600px){
    #pg{
        display: flex; flex-direction: column;  padding-left: 15%; padding-right: 15%;
        width: 70%; 
    }

   
}*/
@media(min-width: 600px){
    #pg{ position: relative; z-index: 0;
        display: flex; flex-direction: column;  padding-left: 10%; padding-right: 10%; box-sizing: border-box;
        width: 100vw; height: 100vh;  align-items: center; box-sizing: border-box; overflow: hidden;
    }

    .p1{
        margin-top: 0%; box-sizing: border-box; width: 90vw;
        overflow: hidden; height: auto;
       display: flex; justify-content: start; align-items: center; 
       position: absolute; z-index: 1; 
       top: 50%;
       left: 50%;
       transform: translate(-50%, -60%);
   }
    .p2{
          width: 100%; max-width: 800px; z-index: 3;
        display: flex; flex-direction: column; justify-content: center; 
        position: absolute; z-index: 5; 
       top: 70%;
       left: 50%;
       transform: translate(-52%, -30%);
    }

    .perfilB{
    
        width: 264px; height: 264px; 
        position: absolute; z-index: 4; 
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%; opacity: 0;
        display: flex; justify-content: center; align-items: center;
        animation-name: perfil; animation-duration: 1s;  
    animation-delay: 1s; animation-fill-mode: forwards;
    }
    
    .bordaPP{
         width: 370px; height: 370px;
         position: absolute; opacity: 1;
         animation: bordaP 1s ease-in-out;  
         animation-delay: 1.1s ; animation-fill-mode: forwards;
        
    }

    .perfilP{
        width: 248px; height: 248px; border-radius: 100%;
    }

   
    .photo{
      
         height: 250px; width: auto; border-radius: 16px; 
         /* Ajuste conforme necessário */
  
       
    }
    .nome{
        font-size: 56px; color: #fff;  z-index: 4;
       margin: 24px auto; font-weight: 700; 
       animation: nome 1s ease-in-out;  transform: scale(0);
        animation-delay: 1s ; animation-fill-mode: forwards;
     
    }
    .btWhats1{
        display: flex; flex-direction: row; justify-content: center; box-sizing: border-box;
        height: 72px; width: 72px; align-items: center; 
        background: #004707; border: 3px solid #2CFA41; 
        border-radius: 56px; gap: 48px; z-index: 50;
        overflow: hidden; position: absolute; bottom: 24px; right: 56px; margin-bottom: 16px;
        max-width: 330px;  transform: translate(0, 50px); /* Estado inicial */
        animation: bts 1s ease-in-out 1.8s both; cursor: pointer; user-select: none;
    }
    .btSaiba1{
        display: flex; flex-direction: row; justify-content: center;
        height: 40px; width: 50%; align-items: center;
        background:  rgba(255,255,255,0.2); /*border: 3px solid #CCCCCC ;*/
        border-radius: 56px;
        overflow: hidden; position: relative; margin-bottom: 16px;
        max-width: 250px;  transform: translate(0, 50px); /* Estado inicial */
        animation: bts 1s ease-in-out 2s both; cursor: pointer; user-select: none;
    
    }

   

    .bioline1{
        display: flex; flex-direction: row; height: 250px;  
       
       scrollbar-width: none; -ms-overflow-style: none;
       
        white-space: nowrap; gap: 8px; opacity: 0;
        animation: 60s slideR infinite linear,
        fadeCarrossel1 1s ease-in-out;
       animation-delay: 1.5s ; animation-fill-mode: forwards;
        
        &::-webkit-scrollbar { display: none;}
    }

    .bioline2{
        display: flex; flex-direction: row; height: 250px;  
       
        scrollbar-width: none; -ms-overflow-style: none;
        
         white-space: nowrap; gap: 8px; opacity: 0;
        animation: 50s slideL infinite linear,
     fadeCarrossel2 1s ease-in-out;
    animation-delay: 1.5s ; animation-fill-mode: forwards;
    &::-webkit-scrollbar { display: none;}
    }

    .bioline3{
        display: flex; flex-direction: row; height: 250px;  
       
       scrollbar-width: none; -ms-overflow-style: none;
       
        white-space: nowrap; gap: 8px; opacity: 0;
        animation: 35s slideR infinite linear,
        fadeCarrossel3 1s ease-in-out;
       animation-delay: 1.5s ; animation-fill-mode: forwards;
        
        &::-webkit-scrollbar { display: none;}
    }
    

} 

@media(min-width: 1024px){
    #pg{ position: relative; z-index: 0;
        display: flex; flex-direction: column;  padding-left: 10%; padding-right: 10%; box-sizing: border-box;
        width: 100vw; height: 100vh;  align-items: center; box-sizing: border-box; 
    }

    .p1{
        margin-top: 0%; box-sizing: border-box; width: 90vw;
        overflow: hidden; height: auto;
       display: flex; justify-content: start; align-items: center; 
       position: absolute; z-index: 1; 
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
   }
    .p2{
          width: 100%; max-width: 800px; z-index: 3;
        display: flex; flex-direction: column; justify-content: center; 
        position: absolute; z-index: 5; 
       top: 70%;
       left: 50%;
       transform: translate(-52%, -20%);
    }

    .perfilB{
    
        width: 264px; height: 264px; 
        position: absolute; z-index: 4; 
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%; opacity: 0;
        display: flex; justify-content: center; align-items: center;
        animation-name: perfil; animation-duration: 1s;  
    animation-delay: 1s; animation-fill-mode: forwards;
    }
    
    .bordaPP{
         width: 370px; height: 370px;
         position: absolute; opacity: 1;
         animation: bordaP 1s ease-in-out;  
         animation-delay: 1.1s ; animation-fill-mode: forwards;
        
    }

    .perfilP{
        width: 248px; height: 248px; border-radius: 100%;
    }

   
    .photo{
      
         height: 220px; width: auto; border-radius: 16px; 
         /* Ajuste conforme necessário */
  
       
    }
    .nome{
        font-size: 56px; color: #fff;  z-index: 4;
       margin: 24px auto; font-weight: 700; 
       animation: nome 1s ease-in-out;  transform: scale(0);
        animation-delay: 1s ; animation-fill-mode: forwards;
     
    }
    .btWhats1{
        display: flex; flex-direction: row; justify-content: center; box-sizing: border-box;
        height: 72px; width: 72px; align-items: center; 
        background: #004707; border: 3px solid #2CFA41; 
        border-radius: 56px; gap: 48px; z-index: 50;
        overflow: hidden; position: absolute; bottom: 24px; right: 56px; margin-bottom: 16px;
        max-width: 330px;  transform: translate(0, 50px); /* Estado inicial */
        animation: bts 1s ease-in-out 1.8s both; cursor: pointer; user-select: none;
    }
    .btSaiba1{
        display: flex; flex-direction: row; justify-content: center;
        height: 40px; width: 50%; align-items: center;
        background:  rgba(255,255,255,0.2); /*border: 3px solid #CCCCCC ;*/
        border-radius: 56px;
        overflow: hidden; position: relative; margin-bottom: 16px;
        max-width: 250px;  transform: translate(0, 50px); /* Estado inicial */
        animation: bts 1s ease-in-out 2s both; cursor: pointer; user-select: none;
    
    }

   

    .bioline1{
        display: flex; flex-direction: row; height: 220px;  
       
       scrollbar-width: none; -ms-overflow-style: none;
       
        white-space: nowrap; gap: 8px; opacity: 0;
        animation: 60s slideR infinite linear,
        fadeCarrossel1 1s ease-in-out;
       animation-delay: 1.5s ; animation-fill-mode: forwards;
        
        &::-webkit-scrollbar { display: none;}
    }

    .bioline2{
        display: flex; flex-direction: row; height: 220px;  
       
        scrollbar-width: none; -ms-overflow-style: none;
        
         white-space: nowrap; gap: 8px; opacity: 0;
        animation: 50s slideL infinite linear,
     fadeCarrossel2 1s ease-in-out;
    animation-delay: 1.5s ; animation-fill-mode: forwards;
    &::-webkit-scrollbar { display: none;}
    }

    .bioline3{
        display: flex; flex-direction: row; height: 220px;  
       
       scrollbar-width: none; -ms-overflow-style: none;
       
        white-space: nowrap; gap: 8px; opacity: 0;
        animation: 35s slideR infinite linear,
        fadeCarrossel3 1s ease-in-out;
       animation-delay: 1.5s ; animation-fill-mode: forwards;
        
        &::-webkit-scrollbar { display: none;}
    }
    

}

@media(min-width: 1920px){
    #pg{ position: relative; z-index: 0;
        display: flex; flex-direction: column;  padding-left: 10%; padding-right: 10%; box-sizing: border-box;
        width: 100vw; height: 100vh;  align-items: center; box-sizing: border-box; 
    }

    .p1{
        margin-top: 0%; box-sizing: border-box; width: 90vw; max-width: 1920px;
        overflow: hidden; height: auto;
       display: flex; justify-content: start; align-items: center; 
       position: absolute; z-index: 1; 
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
   }
    .p2{
          width: 100%; max-width: 800px; z-index: 3; 
        display: flex; flex-direction: column; justify-content: center; 
        position: absolute; z-index: 5; 
       top: 70%;
       left: 50%;
       transform: translate(-52%, -40%);
    }

    .perfilB{
    
        width: 264px; height: 264px; 
        position: absolute; z-index: 4; 
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%; opacity: 0;
        display: flex; justify-content: center; align-items: center;
        animation-name: perfil; animation-duration: 1s;  
    animation-delay: 1s; animation-fill-mode: forwards;
    }
    
    .bordaPP{
         width: 370px; height: 370px;
         position: absolute; opacity: 1;
         animation: bordaP 1s ease-in-out;  
         animation-delay: 1.1s ; animation-fill-mode: forwards;
        
    }

    .perfilP{
        width: 248px; height: 248px; border-radius: 100%;
    }

   
    .photo{
      
         height: 250px; width: auto; border-radius: 16px; 
         /* Ajuste conforme necessário */
  
       
    }
    .nome{
        font-size: 56px; color: #fff;  z-index: 4;
       margin: 24px auto; font-weight: 700; 
       animation: nome 1s ease-in-out;  transform: scale(0);
        animation-delay: 1s ; animation-fill-mode: forwards;
     
    }
    .btWhats1{
        display: flex; flex-direction: row; justify-content: center; box-sizing: border-box;
        height: 72px; width: 72px; align-items: center; 
        background: #004707; border: 3px solid #2CFA41; 
        border-radius: 56px; gap: 48px; z-index: 50;
        overflow: hidden; position: absolute; bottom: 24px; right: 72px; margin-bottom: 16px;
        max-width: 330px;  transform: translate(0, 50px); /* Estado inicial */
        animation: bts 1s ease-in-out 1.8s both; cursor: pointer; user-select: none;
    }
    .btSaiba1{
        display: flex; flex-direction: row; justify-content: center;
        height: 40px; width: 50%; align-items: center;
        background:  rgba(255,255,255,0.2); /*border: 3px solid #CCCCCC ;*/
        border-radius: 56px;
        overflow: hidden; position: relative; margin-bottom: 16px;
        max-width: 250px;  transform: translate(0, 50px); /* Estado inicial */
        animation: bts 1s ease-in-out 2s both; cursor: pointer; user-select: none;
    
    }

   

    .bioline1{
        display: flex; flex-direction: row; height: 250px;  
       
       scrollbar-width: none; -ms-overflow-style: none;
       
        white-space: nowrap; gap: 8px; opacity: 0;
        animation: 60s slideR infinite linear,
        fadeCarrossel1 1s ease-in-out;
       animation-delay: 1.5s ; animation-fill-mode: forwards;
        
        &::-webkit-scrollbar { display: none;}
    }

    .bioline2{
        display: flex; flex-direction: row; height: 250px;  
       
        scrollbar-width: none; -ms-overflow-style: none;
        
         white-space: nowrap; gap: 8px; opacity: 0;
        animation: 50s slideL infinite linear,
     fadeCarrossel2 1s ease-in-out;
    animation-delay: 1.5s ; animation-fill-mode: forwards;
    &::-webkit-scrollbar { display: none;}
    }

    .bioline3{
        display: flex; flex-direction: row; height: 250px;  
       
       scrollbar-width: none; -ms-overflow-style: none;
       
        white-space: nowrap; gap: 8px; opacity: 0;
        animation: 35s slideR infinite linear,
        fadeCarrossel3 1s ease-in-out;
       animation-delay: 1.5s ; animation-fill-mode: forwards;
        
        &::-webkit-scrollbar { display: none;}
    }
    

}
  
.carrossel-wrapper {
    width: 100vw;
    overflow: hidden;
    position: relative;
  }
  
  .carrossel-content {
    display: flex;
    white-space: nowrap;
    animation: scroll 20s linear infinite;
  }
  
 

  .photo-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

.mover {
    transition: transform 1s ease-in-out;
    transform: translateX(-100%);
  }


/* Sobre */

.bgSobre{
    position: fixed; top: 0; left: 0;
    width: -webkit-fill-available;  height: 100%; 
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex;  flex-direction: column; justify-content: center; 
    align-items: center; box-sizing: border-box;  z-index: 1000; overflow: auto;
    backdrop-filter: blur(10px);
     
    
    user-select: none;
    animation: bgTT 0.2s ease-in both; 
}

.cardSobre{
    display: flex; flex-direction: column; margin: auto; width: -webkit-fill-available;
    min-width: 339px; max-width: 340px; height: auto; 
    
     box-sizing: border-box; border-radius:16px; color: #fff; position: relative;
     user-select: none; border: 2px solid rgba(254,236,110,0.3);
     animation: cardTT 0.5s ease-in 0.2s both ; transform: scale(0);
  }

  .sobre--conteudo{
    
    width: 340px; display: flex; flex-direction: column; align-items: center; justify-content: center;
    width: -webkit-fill-available; padding: 0px 24px 24px 24px; 
  }

  .sobre--title{
    display: flex; justify-content: start;
    font-size: 24px; font-weight: 700; width: 100%;
    text-align: left;  opacity: 1;
    /*animation: tittle 0.3s ease-in-out 0.6s forwards;*/
  }

  .sobre--capa{ width: 100%;
    position: relative; z-index: -1;
  }

  .capa--img{
    width: 100%; height: auto; background: #fff; border-radius: 0px 16px 0px 0px;
    opacity: 1; 
    /*animation: fadeBtTT 0.3s ease-in-out 1s forwards;*/
  }

  .endereco{ width: 100%;
    text-align: start; font-size: 12px; line-height: 1.2; text-transform: uppercase; 
  }

 


  .capa--fade{
    position: absolute; bottom: -2px; 
    width: 330px; height: 181px; 
    background: linear-gradient(to top, rgba(0,0,0,1)10%, rgba(0,0,0,0));
  }

.sobre--texto{
    display: flex; justify-content: left;  box-sizing: border-box;
    width: 100%; height: auto; max-height: 246px; overflow-y: auto;
    font-size: 14px; color: #fff; font-weight: 400; text-align: left;
    margin-top: 8px; z-index: 1; margin-bottom: 16px; line-height: 1.1; padding-right: 4px;
    opacity: 1; 
       /* animation: desc 0.3s ease-in-out 0.7s forwards;*/
}

/* Personalizando a barra de rolagem vertical */
::-webkit-scrollbar {
    
  width: 4px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background-color: #fff; /* Cor do "pulgar" da barra de rolagem */
  border-radius: 5px; /* Borda arredondada */
}

::-webkit-scrollbar-track {
  background-color: rgba(255,255,255,0.3);
  border-radius: 6px;
   /* Cor de fundo da barra de rolagem */
}



.photoz {
    width: 100px;
    height: 200px;
    object-fit: cover;
    display: inline-block;
  }

  .bioline1x {
    width: 100%; /* ou defina um tamanho específico */
    height: 250px;
    
    padding: 10px;
    position: relative;
    overflow: hidden;
  }