.plataforma-bg{
    position: fixed; top: 0; left: 0;
   display: flex; flex-direction: column; justify-content: center; align-items: center;
    width: 100%;  height: 100%;
    margin: 0;
    
     font-family: 'Roboto'; box-sizing: border-box;
}

.convites--container{
    width: 90%; max-width: 650px;
   
}

.convite--button{
    display: flex; flex-direction: row; gap: 16px; align-items: center; justify-content: center; font-weight: 700;
    width: 100%; height: 56px; color: #000; font-size: 24px; user-select: none; cursor: pointer;
    background: #FEEC6E; border-radius: 8px; margin-bottom: 32px;
}

.convite--codigos{
    display: flex; flex-direction: column; align-items: center; justify-content: start;
    width: 100%; height: 540px; border-radius: 8px; background: #000; border: 2px solid rgba(254,236,110,0.3);
    overflow-y: auto;
}

.codigo--container{
    width: -webkit-fill-available; color: #fff; gap: 16px; margin: 8px;
    display: flex; flex-direction: row; justify-content: space-between;
}

.ctn--codigo{
    background: rgba(255,255,255,0.1);
    width: -webkit-fill-available; height: 56px; border-radius: 8px; position: relative;
    display: flex; flex-direction: column; align-items: start; justify-content: center; padding-left: 16px;
}

.ctn--user{
    display: flex; flex-direction: column; align-items: center;
    width: 200px; gap: 4px; justify-content: start;
}

.input--user{
    height: 24px; padding: 4px; margin-top: 0;
}

.save--user{
    margin-top: -24px;
}

.ctn--index{
    position: absolute; top: 4px; right: 4px; font-size: 12px; margin: 0;

}

.ctn--cd{
    font-size: 16px; font-weight: 700;
}

.ctn--disp{
    font-size: 12px; color: #71FE6E ;
    display: flex; flex-direction: row; gap: 4px;
}

.ctn--email{
    font-size: 12px; color: #FEEC6E ;
}

.ctn--copy{
    width: 72px; height: 56px; font-size: 24px; color: #FEEC6E ;
    background: rgba(255,255,255,0.2); border-radius: 8px;
    display: flex; align-items: center; justify-content: center;

}