/* cd %USERPROFILE%\Desktop\KT\kt-v2\my-app  */
/* ipconfig  */

*{
    border: 1px none red; 
}

body{
    background: #0b0b0b;
    
}


.bgimg::before { width: 100vh; height: 100vh; 
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/ktalus-tattoo.appspot.com/o/logo%20Ktalus%20Ink%2Fnew%2Fbg-t1.webp?alt=media&token=9d149901-979b-4cce-a9e5-11f8530b2622');
  background-repeat: repeat;
  background-size: 200px 200px;
  opacity: 0.05; /* Define a opacidade da imagem */
  z-index: -1; /* Mantém a imagem atrás de outros conteúdos */
}



.fontRoboto{ font-family: 'Roboto' !important;}
.fontSlab{ font-family: 'Roboto slab'!important;}
.fontSerif{ font-family: 'Roboto serif' !important;}
.fontAlegreya{ font-family: 'Alegreya' !important;}
.fontRajdhani{ font-family: 'Rajdhani' !important;}
.fontPlaypen{ font-family: 'Playpen Sans' !important;}
.fontJosefin{ font-family: 'Josefin Sans' !important;}
.fontPlayfair{ font-family: 'Playfair Display' !important;}
.fontNunito{ font-family: 'Nunito' !important;}
.fontSuse{ font-family: 'SUSE' !important;}


.plataforma-bg{
    position: fixed; top: 0; left: 0;
   display: flex; flex-direction: column; justify-content: center; align-items: center;
    width: 100%;  height: 100%;
    margin: 0;
     background: #191919; 
     font-family: 'Montserrat';
     transition: opacity 0.2s ease-in-out;
}

.plataforma-bg2{
 
 display: flex; flex-direction: column; justify-content: center; align-items: center;
  width: 100%;  height: 100%;
  margin: 0;
 
   font-family: 'Montserrat'; border-radius: 16px;
   transition: opacity 0.2s ease-in-out;
}
.plataforma{
   
   display: flex; flex-direction: row; justify-content: space-evenly; box-sizing: border-box;
    width: 100vw; overflow-y: auto;
   /*padding-left: 24px; padding-right: 24px;*/ gap: 8px;
    flex-wrap: wrap; margin: auto 0; opacity: 1;
    transition: opacity 0.3s ease-in-out; position: relative;
}

.logoK {

padding: 48px 0px 48px 0px;
border-radius: 16px 0px 0px 0px;
/*background: linear-gradient(to bottom, #665400, #181818);*/
}
.main1{
   
  display: flex; flex-direction: row;
  gap: 8px; box-sizing: border-box;
  
  justify-content: center; align-items: center;
  width: 100%; height: 100%; /*border: #fff solid 1px;*/

  
}

.plataformaV2{
   
  display: flex; flex-direction: column; justify-content: start; 
  background: rgba(0,0,0,0.7); 
   backdrop-filter: blur(10px); /* Aplica o desfoque ao fundo */
  -webkit-backdrop-filter: blur(10px);
   width: 245px; height: 90vh; margin-top: 48px; box-sizing: border-box;
   /*transition: opacity 0.3s ease-in-out;*/ 
   /*border: #FFD200  solid 1px;*/ border-radius: 16px; position: relative;
}

.plataformaV2P2{
   
  display: flex; flex-direction: row; justify-content: center; align-items: start; 
   width: 835px; height: 90vh; overflow-y: auto; margin-top: 48px;
   background: rgba(0,0,0,0.7); 
   backdrop-filter: blur(10px); /* Aplica o desfoque ao fundo */
  -webkit-backdrop-filter: blur(10px);
   /*transition: opacity 0.3s ease-in-out;*/ 
   /*border: #FFD200  solid 1px;*/ border-radius: 16px;
}

.plataforma-borderV2{
  display: flex; justify-content: center; align-items: center;
  height: 90px; width: 285px;
  background: linear-gradient(150deg, rgba(223,252,1,0), rgba(223,252,1,1));
  border-radius: 0px 16px 0px 16px; 
  position: relative;  
  box-shadow: 4px 4px 8px rgba(0,0,0,0.75);
  transform:scale(0.95); transition: all 0.3s; 
  transform: translateX(40px); opacity: 0;
}

hr {
  border: none;
  height: 1px; width: 100%;
  background: linear-gradient(to right, #dffc01 0%, rgba(rgba(223,252,1,0),0) 100%); /* Cor do divisor */
  margin: 0; 
}

.bgly {
background-color: rgba(255, 255, 255, 0.07);
}

.bgly2 {
  background-color: rgba(255, 255, 255, 0.1);
  }

  .bgly3 {
    background-color: rgba(0,0,0, 0.3);
    backdrop-filter: blur(5px); /* Aplica o desfoque ao fundo */
  -webkit-backdrop-filter: blur(10px);
    }
  

.plataforma-border{ font-family: 'montserrat'; font-weight: 700; flex-direction: column;
    display: flex; justify-content: center; align-items: center;
    height: 90px; width: 176px; gap: 8px; cursor: pointer;
    background: linear-gradient(45deg, #dffc01 -100%,#191919 130%); 
   backdrop-filter: blur(10px); /* Aplica o desfoque ao fundo */
  -webkit-backdrop-filter: blur(10px);
    border-radius: 16px; 
    position: relative;  
    color: #fff;
    /*transform:scale(0.95); transition: all 0.3s; 
    transform: translateX(40px); opacity: 1;*/ user-select: none;
}
@media only screen and (max-width: 375px) {
  .plataforma-border{ font-family: 'montserrat'; font-weight: 700; flex-direction: row;
    display: flex; justify-content: center; align-items: center; font-size: 16px;
    height: 60px; width: 176px; gap: 8px; cursor: pointer;
    background: linear-gradient(45deg, #dffc01 -100%,#191919 130%); 
   backdrop-filter: blur(10px); /* Aplica o desfoque ao fundo */
  -webkit-backdrop-filter: blur(10px);
    border-radius: 16px; 
    position: relative;  
    color: #fff;
    /*transform:scale(0.95); transition: all 0.3s; 
    transform: translateX(40px); opacity: 1;*/ user-select: none;
}

}



.plataforma-borderSDI{
  display: flex; justify-content: center; align-items: center;
  height: 90px; width: 156px;
  background:rgba(254,236,110,1);
  border-radius: 0px 16px 0px 16px; 
  position: relative;  
  box-shadow: 4px 4px 8px rgba(0,0,0,0.75);
  transform:scale(0.95); transition: all 0.3s; 
  transform: translateX(40px); opacity: 0;
}
/*
#plat01{ animation: botaoPlat 0.2s ease-in-out forwards;}
#plat02{ animation: botaoPlat 0.2s ease-in-out 0.2s forwards;}
#plat03{ animation: botaoPlat 0.2s ease-in-out 0.4s forwards;}
#plat04{ animation: botaoPlat 0.2s ease-in-out 0.6s forwards;}
#plat05{ animation: botaoPlat 0.2s ease-in-out 0.8s forwards;}
#plat06{ animation: botaoPlat 0.2s ease-in-out 1s forwards;}
#plat07{ animation: botaoPlat 0.2s ease-in-out 1.2s forwards;}

#plat08{ animation: botaoPlat 0.2s ease-in-out 1.4s forwards;}
#plat09{ animation: botaoPlat 0.2s ease-in-out 1.6s forwards;}
#plat10{ animation: botaoPlat 0.2s ease-in-out 1.8s forwards;}*/

@keyframes botaoPlat {
  0%{transform: translateX(40px); opacity: 0;}
  100%{transform: translateX(0); opacity: 1;}
}

/*.plataforma-border:hover{
    display: flex; justify-content: center; align-items: center;
    height: 90px; width: 156px;
    background: linear-gradient(150deg, rgba(254,236,110,0), rgba(254,236,110,1));
    border-radius: 0px 16px 0px 16px; 
    position: relative;  
    box-shadow: 4px 4px 8px rgba(0,0,0,0.75);
    transform:scale(1);
}*/

.plataforma-botao{
    display: flex; flex-direction: column; align-items: center; justify-content: center;
    height: 88px; width: 154px;
    background: linear-gradient(to bottom, #000, #181818);
    border-radius: 0px 16px 0px 16px; cursor: pointer;
    color: #FEEC6E; font-size: 18px; font-weight: 700; gap: 8px;

}

.plataforma-botaoV2{
  display: flex; flex-direction: row; align-items: center; justify-content: start;
  height: 48px; width: 229px; padding: 0px 0px 0px 32px;
  font-family: "Montserrat", sans-serif; 
   cursor: pointer;
  color: #FFf; font-size: 20px; font-weight: 400; gap: 8px;
  
}

.plataforma-botaoV2Act{
  display: flex; flex-direction: row; align-items: center; justify-content: start;
  height: 48px; width: 210px; padding: 0px 0px 0px 8px; margin-left: 24px;
  font-family: "Montserrat", sans-serif; border-radius: 16px 0px 0px 16px;
   cursor: pointer;
  color: #fff; font-size: 22px; font-weight: 700; gap: 8px;
  transition: 0.1s ease-in-out; background: linear-gradient(to right, rgba(223,252,1,100) -30%, rgba(223,252,1,0) 100%);
}

.plataforma-botaoV2:hover{
  display: flex; flex-direction: row; align-items: center; justify-content: start;
  height: 48px; width: 210px; padding: 0px 0px 0px 8px; margin-left: 24px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)); border-radius: 16px 0px 0px 16px;
   cursor: pointer;
  color: #fff; font-size: 20px; font-weight: 700; gap: 8px;
  /*transition: 0.3s ease-in-out;*/

}
.plataforma-botaoExit{ font-family: 'Montserrat';
  display: flex; flex-direction: row; align-items: center; justify-content: center;
  height: 30px; width: 100px; 
  color: #aaaaaa; font-size: 16px; font-weight: 400; gap: 8px; cursor: pointer;
  position: absolute; bottom: 30px; user-select: none;
}



.plataforma-botaoExit2{ font-family: 'Montserrat';
    display: flex; flex-direction: row; align-items: center; justify-content: center;
    height: 30px; width: 100px; 
    color: #aaaaaa; font-size: 16px; font-weight: 400; gap: 8px; cursor: pointer;
    position: absolute; bottom: -16px; right: 0px;
}

.plataforma-cantoBotaoA{
    width: 24px; height: 24px; 
    position: absolute; top: 0; left: 0;
    transform: rotate(180deg); opacity: 0.7;
    
}
.plataforma-cantoBotaoD{
    width: 24px; height: 24px; 
    position: absolute; bottom: 0; right: 0;
     opacity: 0.7;
    
}

.regressivo{
  background: #dffc01; color: #000;
  display: flex; align-items: center; justify-content: center;
  width: 100%; height: 32px; gap: 4px;
}


/* Header Page */

.bgHeaderPage{
    display: flex; flex-direction: row; justify-content: left; align-items: center;
    background: linear-gradient(90deg, #191919 0%, #808080 150% );
    height: 72px; padding: 0px 16px;  width: -webkit-fill-available;
    position: relative; z-index: 1; gap: 16px; user-select: none; transform: translateY(-80px);
    animation: InHeader 0.3s ease-in-out forwards; 
  }


  @keyframes InHeader{
    0%{transform: translateY(-80px);}
    100%{transform: translateY(0);}
  }

.backPlataform{
    color: #fff; font-size: 32px;
} 

.logoKtalus2{
    height: 40px;
}

.nomePagina {
    font-size: 28px;
    font-weight: 700;
    
    background: linear-gradient(to right, #dffc01, #718001);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }


  /* 01 Link */

  .linkpage, .catalogoPage, .pgInicialPage,  .PerfilPage, .personalizarPage, .AssinaturaPage {
    display: flex; flex-direction: column; align-items: center;
    width: 100%; height: 100vh;  
    opacity: 1;
    /*animation: fadeProgress 1s ease-in-out forwards; padding-bottom: 32px;*/

    
  }

  @keyframes fadeProgress{
    0%{opacity: 0;}
    100%{opacity: 1;}

}

  .alertaCopy{
    display: flex; flex-direction: row; align-items: center; gap: 8px;
    padding: 4px 8px; 
    background: rgba(232,252,1,0.1); color: #dffc01;
    border-radius: 8px; 
  }

  .linkpage--qr, .linkpage--lk{
    display: flex; flex-direction: column; align-items: center; justify-content: center;
    gap: 16px; margin-top: 64px;
  }
  .linkpage--qrcodeB{
    display: flex; justify-content: center; align-items: center;
    width: 320px; height: 320px; background: #fff; position: relative;
    border-radius: 0px; overflow: hidden; opacity: 0;
    animation: fadeProgress 1s ease-in-out forwards;
}

  .linkpage--qrcodeSc{
    width: 180px; height: 180px; background: #dffc01;
  }

  .qrcodeBCantoD{
    width: 48px; height: 48px; position: absolute;
    bottom: 4px; right: 4px;
  }  

  .qrcodeBCantoA{
    width: 48px; height: 48px; position: absolute;
    top: 4px; left: 4px; transform: rotate(180deg);
  } 

  .btPadrao{
    display: flex; flex-direction: row; align-items: center; justify-content: center;
    background: #997E00; gap: 8px;
    width: 224px; height: 56px; position: relative;
    color: #fff; font-weight: 700; font-size: 18px; cursor: pointer;
    border-radius: 8px; 
  }

 

  .cantoBotaoA{
    width: 24px; height: 24px; 
    position: absolute; top: 0; left: 0;
    transform: rotate(180deg); opacity: 0.7;
    
}
.cantoBotaoD{
    width: 24px; height: 24px; 
    position: absolute; bottom: 0; right: 0;
     opacity: 0.7;
    
}
  

.linkpage--link{
  display: flex; flex-direction: row; align-items: center; justify-content: left;
  color: #Fff;
  height: 56px; width: 90%; font-size: 16px; background: #505050;
   border-radius: 8px; max-width: 400px;
  margin-top: 64px; margin-bottom: 32px;
}

.textLink{ font-weight: 700; color: #fff;}


/* Perfil */
.perfilPage--container{
  display: flex; flex-direction: column; justify-content: center; 
  width: -webkit-fill-available; max-width: 400px; margin: 32px 24px; 
  /*background: #fff;*/
}

.pr--uploadPerfil{
  display: flex; flex-direction: column; 
  justify-content: center; align-items: center;
  width: 154px; height: 154px; 
  border: 2px solid white; font-size: 16px; 
  border-radius: 100%; cursor: pointer; color: #dffc01;
}

.perfilPage--fotoContainer{
  display: flex; flex-direction: column; align-items: center; justify-content: center;
  gap: 8px; padding: 16px; border-radius: 16px;
}

.fotoContainer--foto{
  width: 154px; height: 154px; background: #fff;
  border-radius: 100%;
}

.fotoContainer--remover{
  display: flex; flex-direction: row; align-items: center; justify-content: center;
  gap: 8px; margin: 0;
  width: 124px; height: 32px; background: rgba(223,252,1,0.1); color: #dffc01 ; border-radius: 32px;
}


.perfilPage--nomeContainer, .perfilPage--WhatsContainer, .perfilPage--linkContainer{
  display: flex; flex-direction: column; align-items: center; justify-content: center;
   color: #dffc01; width: 100%; 
}

.nomeContainer--nomePreview{
  display: flex; flex-direction: row; align-items: center; justify-content: center;
  gap: 8px; color: #dffc01; width: 100%;
}

.nomeContainer--nome{
  display: flex; align-items: start; justify-content: start;
font-size: 24px; color: #fff; font-weight: 700;

}

.nomeContainer--link{
  display: flex; align-items: start; justify-content: start;
font-size: 16px; color: #fff; font-weight: 700;
 font-style: italic;
}

.nomeContainer--buttonEdit{
  display: flex; align-items: center; justify-content: center;
  width: 48px; height: 48px; background: rgba(255,255,255,0.1); border-radius: 100%;
  font-size: 24px; color: #FEEC6E;
}

.nomeContainer--buttonEditDK{
  display: flex; align-items: center; justify-content: center;
  width: 80px; height: 24px; background: rgba(223,252,1,0.1); border-radius: 16px;
  font-size: 14px; color: #DFFC01; gap: 4px; font-weight: 400; cursor: pointer;
}

.nomeContainer--nomeEdit, .nomeContainer--whatsEdit, .nomeContainer--linkEdit, .nomeContainer--DescEdit{
  display: flex; flex-direction: column; align-items: end;
  gap: 8px; color: #fff; width: 100%; max-width: 350px; position: relative;
}



.nomeEditInput{
  max-width: 400px; width: 100%; position: relative;
}



.contadorNome{
  display: flex; flex-direction: row;
  position: absolute; right: 8px; bottom: 28px; font-size: 10px;
}

.buttonsEdit{
  display: flex; flex-direction: row; align-items: end;
  margin-top: 0px; gap: 16px; user-select: none;
}

.saveButtonEdit{
  display: flex; align-items: center; justify-content: center;
  width: 108px; height: 32px; color: #fff;
  background: linear-gradient(to bottom, #718001 60%, #dffc01 130%); 
      cursor: pointer; border: #DFFC01 solid 1px;
  border-radius: 16px;  z-index: 1; user-select: none;
  /*box-shadow: inset 0 0 0 2px #dbaf00;*/  -webkit-user-select: none; /* Para navegadores baseados em Webkit */
  -ms-user-select: none;
}

.disabled{
  display: flex; align-items: center; justify-content: center;
  width: 108px; height: 32px; color: #fff;
  background: linear-gradient(to bottom, #718001 60%, #dffc01 130%); 
      cursor: pointer; border: #DFFC01 solid 1px;
  border-radius: 16px;  z-index: 1; user-select: none;
  /*box-shadow: inset 0 0 0 2px #dbaf00;*/
  opacity: 0.5;
}

.cancelButtonEdit{
  display: flex; align-items: center; justify-content: center;
  width: 108px; height: 32px; color: #DFFC01;
  background: transparent; border: 1px solid #DFFC01; 
  border-radius: 16px; cursor: pointer; z-index: 1; user-select: none; -webkit-user-select: none; /* Para navegadores baseados em Webkit */
  -ms-user-select: none;
}

/* Pagina incial*/

.pgInicialPage--container{
  display: flex; flex-direction: column; justify-content: start; align-items: start;
  width: -webkit-fill-available; margin: 24px;  gap: 8px; box-sizing: border-box;
  /*background: #fff; border: #fff solid 1px;*/
}

.pgInicialPage--Carrossel{
  color: #DFFC01; display: flex; flex-direction: column;
  align-items: start; justify-content: center; font-size: 24px; font-weight: 700;
/*background: rgba(255, 255, 255, 0.1);*/ border-radius: 16px;
padding: 16px; width: 100%; box-sizing: border-box;  user-select: none;
}


.carrossel--container{
 display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px;
  gap: 16px; width: 100%;
}

.carrossel--itemEmpty{
  display: flex; flex-direction: column; align-items: center; justify-content: center;
  width: 73px; height: 92px; gap: 4px; cursor: pointer; user-select: none;
   border: 1px solid #DFFC01; color: #fff; font-size: 12px;
   background: rgba(255,255,255,0.1); border-radius: 8px; font-weight: 400;
}

.carrossel--item{
  display: flex; flex-direction: column; align-items: center; justify-content: center;
  width: 73px; height: 92px; gap: 4px;
   border: 1px solid #DFFC01; color: #fff; font-size: 16px;
   background: rgba(255,255,255,0.1); border-radius: 8px; position: relative;
}

.CRSImg{
  width: 73px; height: 92px; border-radius: 8px;
}

.removeCRS{
  position: absolute; top: 1px; right: 1px; font-size: 24px; background: rgba(0,0,0,0.75); border-radius: 0px 8px 0px 0px
}

.pgInicialPage--saibamais{
  color: #fff; display: flex; flex-direction: column;
  align-content: start; justify-content: start; width: -webkit-fill-available;
  margin-top: 8px;  border-radius: 16px;
  padding: 16px; /*width: -webkit-fill-available; max-width: 678px;*/
}

.saibamais--title{
  display: flex; flex-direction: row; gap: 16px; align-items: center;
  font-size: 24px; font-weight: 700; text-align: left; color: #DFFC01; user-select: none;
}

.saibamais--container{
  display: flex; flex-direction: column; justify-content: center; align-items: center;
  
}

.fotoContainer--fotoSM{
  
  width: 224px; height: 126px; background: #fff;
  border-radius: 8px; 
}

.fotoContainer--fotoSMEmpty{
  
  width: 126px; height: 126px; background: rgba(255,255,255,0.1);
  border-radius: 80px; /*border: 1px solid #FEEC6E;*/ color: #fff; font-size: 18px;
  display: flex; flex-direction: column; align-items: center; justify-content: center; margin: 0px auto 0px auto;
}

.descricaotittle{
  display: flex; flex-direction: row; justify-content: start; align-items: center; gap:16px;
  
}

.conteudoSobre{
  font-size: 16px; text-align: left;  width: 100%;
  color: #D9DACE; user-select: none;
}

.descEditInput{
  width: 60%;
}

textarea{
  padding: 16px; font-family: 'Roboto'; font-size: 14px;
}

.contadorDesc{
  display: flex; flex-direction: row; position: absolute; bottom: -8px;
   font-size: 10px; margin-top: 8px; color: #fff;
}

.sobrePage--fotoContainer{
  display: flex; flex-direction: column; align-items: center; justify-content: center;
 /*border: #fff solid 1px;*/ width: -webkit-fill-available; border-radius: 16px; gap: 8px;
 padding: 16px;
}

@media(min-width: 600px){
  .saibamais--container{
    display: flex; flex-direction: row; justify-content: center; align-items: start;
    width: 100%; gap: 40px;
  }

  .sobrePage--fotoContainer{
    display: flex; flex-direction: column; align-items: center; justify-content: center;
    gap: 8px; margin-top: 48px;
  }
}


/* Personalizar*/

.personalizarPage--container{
  display: flex; flex-direction: column; justify-content: center; 
  width: -webkit-fill-available; max-width: 678px; margin: 32px 24px; 
  /*background: #fff;*/ user-select: none;
}

.personalizar--previewcontainer, .personalizar--corcontainer{
 display: flex; flex-direction: column; align-items: start; color: #fff;
 font-size: 18px; font-weight: 700; 
}

.previewPerfilb{
  opacity: 0.5;
}
.previewcontainer{
  width: 100%; 
  display: flex; flex-direction: row; align-items: center; justify-content: center;
}

.previewPerfilPerso{
  width: 100%; height: 72px; border-radius: 16px; background: rgba(255,255,255,0.3);
   font-size: 20px; font-weight: 700;
  display: flex; flex-direction: row; align-items: center; justify-content: center;
  position: relative; max-width: 350px;
}

.personalizar--cor{
  width: 48px; height: 48px; border-radius: 16px;
  background: rgba(255,255,255,0.1); border: 2px solid #dffc01;
}

.personalizar--fonte{
  display: flex; align-items: center; justify-content: center;
  width: 88px; height: 48px; border-radius: 16px;
  background: rgba(255,255,255,0.1); border: 2px none #dffc01; font-weight: 400;
}

.personalizar--estilo{
  display: flex; align-items: center; justify-content: center;
  width: 88px; height: 88px; border-radius: 8px;
  background: rgba(255,255,255,0.1); 
}

.persoBoxEdit{
  width: 100%; gap: 24px;
  display: flex; flex-direction: column; align-items: center; justify-content: center;
}

.estilo--container{
  display: flex; align-items: center; justify-content: center;
}

/* Assinatura*/

.assinaturaPage--container{
  display: flex; flex-direction: column; justify-content: center; 
  width: -webkit-fill-available; max-width: 400px; margin: 32px 24px; 
  /*background: #fff;*/
}

.assinatura--test, .assinatura--cliente{
  width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;
color: #fff; gap: 32px;
}

.assinatura--info{
  display: flex; flex-direction: row;  justify-content: space-between; align-items: center;
 width: 100%;
}

.assinatura--info2{
  display: flex; flex-direction: column;  justify-content: space-between; align-items: center;
 width: 100%; gap: 16px; margin-bottom: 32px;
}

.assinatura--if{
  display: flex; flex-direction: column; justify-content: left; align-items: start;
color: #dffc01;
}

.assinatura--if2{ width: 100%; text-align: left;
  display: flex; flex-direction: row; justify-content: space-between; align-items: start;
color: #dffc01;
}


.spInfo{
  color: #Fff; font-weight: 700; text-align: right;
}

.botoesCliente{
  display: flex; flex-direction: column; align-items: center; justify-content: center;
  width: 100%; gap: 16px;
}

.btAssinatura{
  display: flex; justify-content: center; align-items: center;
  width: 100%; max-width: 300px; height: 48px; cursor: pointer;
  color: #fff; font-size: 20px; font-weight: 700;
  /*background: linear-gradient(to bottom, #718001 60%, #dffc01 130%);   border: #dffc01 solid 2px;*/
  border-radius: 16px; cursor: pointer;
}



.btAssinaturaCancel{
  display: flex; justify-content: center; align-items: center;
  width: 100%; max-width: 300px; height: 56px;
  color: #c2c2c2; font-size: 16px; 
  background: transparent; cursor: pointer;
 
}

.cardAssM{
  display: flex; flex-direction: column; margin: auto; width: 90%;
  min-width: 339px; max-width: 400px; height: auto; padding: 16px;
  background: linear-gradient(135deg, #808080 0%, #191919 45%);
   box-sizing: border-box; border-radius:32px; color: #fff; position: relative;
   user-select: none; border: 2px solid #dffc01;
}

.cardAssA{
  display: flex; flex-direction: column; margin: auto; width: 90%;
  min-width: 339px; max-width: 400px; height: auto; padding: 16px;
  background: linear-gradient(135deg, #DFFC01 -30%, #191919 45%);
   box-sizing: border-box; border-radius:32px; color: #fff; position: relative;
   user-select: none; border: 2px solid #dffc01;
}

.ass--modo{
  display: flex; flex-direction: row; justify-content: center; align-items: center;
   margin-bottom: 24px; font-size: 18px; gap: 16px; cursor: pointer; padding: 2px 4px 2px 4px;
   background: rgba(255, 255, 255, 0.2); width: 226px; height: 45px; border-radius: 16px;
}

.ass-modoMensal, .ass-modoAnual{
  font-family: 'montserrat'; display: flex; flex-direction: column; justify-content: center; align-items: center;
}

.modoAssativo{
display: flex; align-items: center; justify-content: center; color: #000; background: #DFFC01;
width: 107px; height: 37px; border-radius: 10px; font-size: 18px; font-weight: 700;
}

.toggleM{
  display: flex; flex-direction: row; align-items: center; justify-content: start;
  width: 56px; height: 24px; background: #fff; border-radius: 24px;
}

.toggleA{
  display: flex; flex-direction: row; align-items: center; justify-content: end;
  width: 56px; height: 24px; background: #CCBE58; border-radius: 24px;
}

.toggleM--ballM{
  width: 18px; height: 18px; background: #CCBE58; border-radius: 16px; margin-left: 4px;
}

.toggleA--ballA{
  width: 18px; height: 18px; background: #fff; border-radius: 16px; margin-right: 4px;
}

.ass--itens{
  display: flex; flex-direction: column; justify-content: left; gap: 8px;
  margin-bottom: 32px;
}

.ass--item{
  display: flex; flex-direction: row; align-items: center; justify-content: left; gap: 8px; font-size: 16px;
}

.ass--preco{
  display: flex; flex-direction: row; justify-content: center; align-items:top;
  width: 100%; position: relative; color: #dffc01; margin-left: -40px;
}

.ass--mes{
  font-size: 20px; position: absolute; right: -40px; bottom: 10px; font-weight: '100';
}

.ass--anual{
  font-size: 16px; position: absolute; right: -8px; bottom: 0px;
}

@keyframes brilhog {
  0% {
    text-shadow: 0 0 5px #fff;
    transform: scale(1);
  }
  50% {
    text-shadow: 0 0 10px #fff;
    transform: scale(1.03);
  }
  100% {
    text-shadow: 0 0 5px #fff;
    transform: scale(1);
  }
}

@keyframes brilhog2 {
  0% {
    
    transform: scale(1);
  }
  50% {
    
    transform: scale(1.03);
  }
  100% {
    
    transform: scale(1);
  }
}

.brilho-texto {

  
  animation: brilhog 1s infinite; /* Animação de brilho com duração de 1s e repetição infinita */
}


.ass--botaoCTA{
  display: flex; align-items: center; justify-content: center;
  width: 280px; height: 54px; border-radius: 8px; margin-top: 32px; margin-bottom: 8px; margin-left: auto; margin-right: auto;
  color: #fff; font-weight: 700; font-size: 20px; cursor: pointer;
  background-image: linear-gradient(70deg,
    #7D6E00, #CCBE58, #7D6E00, #CCBE58);
    /*border: 2px solid #FF0000;*/
  background-size: 400% 200%; transition: background 5s ease, box-shadow 0.5s;
  animation: brilho 6.4s cubic-bezier(0.2, 0.5, 0.9, 0.6) 0s infinite
}

.ass--botaoCTAM{
  display: flex; align-items: center; justify-content: center;
  width: 280px; height: 54px; border-radius: 48px; margin-bottom: 8px; margin-left: auto; margin-right: auto;
  color: #dffc01; font-weight: 700; font-size: 20px; cursor: pointer;
  background-image: linear-gradient(70deg,
    #191919, #718001, #191919, #718001);
    border: 2px solid #dffc01;
  background-size: 400% 200%; transition: background 5s ease, box-shadow 0.5s;
  animation: brilho 6.4s cubic-bezier(0.2, 0.5, 0.9, 0.6) 0s infinite
}

.ass--botaoCTAA{
  display: flex; align-items: center; justify-content: center;
  width: 280px; height: 54px; border-radius: 48px; margin-bottom: 8px; margin-left: auto; margin-right: auto;
  color: #fff; font-weight: 700; font-size: 20px; cursor: pointer;
  background-image: linear-gradient(70deg,
    #889A00, #DFFC01, #889A00, #DFFC01);
    border: 2px solid #dffc01;
  background-size: 400% 200%; transition: background 5s ease, box-shadow 0.5s;
  animation: brilho 6.4s cubic-bezier(0.2, 0.5, 0.9, 0.6) 0s infinite , brilhog2 1s infinite;
}

.ass--botaoPatual{
  display: flex; align-items: center; justify-content: center;
  width: 280px; height: 54px; border-radius: 48px;  margin-bottom: 8px; margin-left: auto; margin-right: auto;
  color: #dffc01; font-weight: 700; font-size: 20px; cursor: pointer; background-color: rgba(223,252,1,0.1);
}

@keyframes brilho {0% {background-position: 100% 0;} 100% {background-position: 0 0;}}


.closePlan{
  display: flex; align-items: center; justify-content: center;
  height: 32px; width: 32px; background: rgba(255,255,255,0.15); border-radius: 4px;
  position: absolute; top: 16px; right: 16px;
  color: #fff; font-size: 24px; z-index: 2; cursor: pointer;
}


.AssCantoA{
  height: 64px; width: 64px; position: absolute; top: -2px; left: -2px;
  transform: rotate(180deg);
}
.AssCantoD{
  height: 64px; width: 64px; position: absolute; bottom: -2px; right: -2px;
}



/* Suporte */
.suporte--modo{
  display: flex; flex-direction: column; justify-content: center; align-items: center;
   margin-bottom: 24px; font-size: 18px; gap: 8px; cursor: pointer;
}

.suporte--botao{
  display: flex; align-items: center; justify-content: center;
  width: 280px; height: 54px; border-radius: 8px; margin-top: 32px; margin-bottom: 8px; margin-left: auto; margin-right: auto;
  color: #fff; background: #997E00; font-weight: 700; font-size: 20px; cursor: pointer;
}

#suportecard{
  opacity: 0;
  animation: fadeBtTT 0.5s ease forwards;
}


.oferta{
  display: flex; flex-direction: row; user-select: none;
  background:  rgba(223,252,1,0.1); padding: 24px 16px; color: #DFFC01;
  margin: 16px; max-width: 360px; width: -webkit-fill-available;
  border-radius: 16px; justify-content: space-between; align-items: center; border: #DFFC01 solid 2px;
}

.ofertaDk{
  display: flex; flex-direction: column; user-select: none;
  background: rgba(223,252,1,0.1); padding: 16px; color: #DFFC01;
  margin: 16px; max-width: 360px; width: -webkit-fill-available;
  border-radius: 16px; justify-content: center; align-items: center; border: #DFFC01 solid 2px;
}
.oferta--tx{
  display: flex; flex-direction: row; gap: 8px;
  text-align: left; font-size: 16px; font-weight: 700;
  align-items: center; line-height: 1;
 
}

.oferta--txdk{
  display: flex; flex-direction: row; gap: 16px;
  text-align: center; font-size: 16px; font-weight: 700;
  align-items: center; 
 
}
.oferta--val{
  display: flex; flex-direction: row;
  text-align: left; font-size: 14px; color: rgba(255,255,255,1);
  align-items: center;  text-decoration: underline; font-weight: 400;
 
}

.oferta--valdk{
  display: flex; flex-direction: row; justify-content: center;
   font-size: 14px; color: rgba(255,255,255,1);
  align-items: center; text-decoration: underline;
 
}

.oferta--tx1{
  display: flex; flex-direction: column; gap: 8px;
  text-align: left; font-size: 16px; font-weight: 700;
  align-items: start; justify-content: start;
 
}

.oferta--bt{
  display: flex; align-items: center; justify-content: center;
  padding: 4px 16px; background: #dffc01; border-radius: 50px; gap: 4px;
  color: #000; height: 32px; font-weight: 700; cursor: pointer; line-height: 1;
}

.oferta--btdk{
  display: flex; align-items: center; justify-content: center;
  padding: 8px 16PX; background: #dffc01; border-radius: 50PX;
  color: #000; font-weight: 700; cursor: pointer; line-height: 1;
  margin-top: 16px; gap: 4px;
}

.video-container {
  position: relative;
  width: 100%; border-radius: 16px;
   /* Defina um limite para a largura máxima se desejar */
  height: 640px; /* Altura maior que a largura para simular portrait */
  margin: 0 auto; /* Centralizar o vídeo */
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; border-radius: 16px;
}