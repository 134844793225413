.loginP{
    background: #14161a;
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    height: 100vh;
    margin: 0;
}

.registerP, .loginPa{ font-family: 'montserrat'; flex-direction: column; 
 display: flex; justify-content: start; align-items: center; box-sizing: border-box;
 width: 100%; height: 100%; position: fixed; top: 0; left: 0;
 background: #191919; box-sizing: border-box; padding: 24px; color: #fff; overflow-y: auto; overflow-x: hidden;
}

.r--card{
    display: flex; flex-direction: column; align-items: center;
    background: linear-gradient(135deg, #191919, #000); box-sizing: border-box;
    border: 2px solid #dffc01;
     width: 100%; max-width: 400px; min-width: 300px; border-radius: 16px;
    padding-bottom: 40px;  position: relative; margin: 0px 24px; font-family: 'montserrat';
     
}

.r--header{
   
    display: flex; flex-direction: column; justify-content: center; align-items: center; 
    margin-top: 40px; margin-bottom: 16px;  width: 100%; gap: 8px;max-width: 400px; box-sizing: border-box;
}

.r--form{
    display: flex; flex-direction: column; width: 100%; min-width: 268px; max-width: 368px;
    align-items: center;  margin-top: 16px; margin-left: 16px; margin-right: 16px; box-sizing: border-box;
    
}

.form--input{
    width: -webkit-fill-available; margin: 0px 16px;
}
.h1sub{
    color: white; text-align: center; font-size: 18px; 
    font-weight: 400; 
}

/*div{
display: flex; flex-direction: column; justify-content: left;
}*/

/*form{
    width: -webkit-fill-available;
}*/

input, button{
    padding: 16px;
    margin-bottom: 16px;
    margin-top: 8px;
    background: rgba(255,255,255,0.1);
    border-radius: 8px;
    border: none;
    color: white;
    font-size: 16px;
    width: -webkit-fill-available;
    
    
    
}

input::placeholder{
    color: rgba(255,255,255,0.6);
    font-size: 16px;
}

.senha{
    position: relative; display: flex; flex-direction: row;
}

input:focus{
    border-color:  #dffc01 !important;
    background: transparent; 
}

.infoSenha{
    display: flex; justify-content: start; 
    width: 100%; font-size: 12px; color: rgba(255,255,255,0.5);
    font-style: italic; margin-top: -16px; margin-bottom: 20px;
}

.view{
    position: absolute;
    right: 10px;
    top: 41%;
    transform: translateY(-41%);
    cursor: pointer;
    color: rgba(255,255,255,0.6); z-index: 2; font-size: 30px;
}

.view2{
    position: absolute;
    right: 10px;
    bottom: 34px;
    
    cursor: pointer;
    color: rgba(255,255,255,0.6); z-index: 2; font-size: 30px;
}

.label{
    color: #dffc01; width: 100%;
   display: flex; justify-content: left; font-weight: 400; font-size: 14px;
}

.label2{
    color: white; width: 100%; margin-top: -16px; text-decoration: underline;
   display: flex; justify-content: right; font-weight: 100; font-size: 14px;
   cursor: pointer; user-select: none;
}

.form--resetSenha{
    font-size: 16px; 
}
.clear{
    background: transparent;
    border: none;
    color: white;
}

.login{
    background-color: #dffc01; width: -webkit-fill-available;
    border: none; display: flex; align-items: center; justify-content: center; flex-direction: row;
    border-radius: 16px;
    color: #000; font-weight: 700; font-size: 16px; margin: 32px 16px;

}

/*.alert{
    font-size: 14px; color: #dffc01; background: rgba(223, 252, 1, 0.1); height: auto;
    display: flex; align-items: center; justify-content: center; width: 100%;
    font-weight: 400; margin-bottom: 8px; padding: 8px 0;
}*/
.new{
    background-color: transparent;
    border: 1px solid orange;
    border-radius: 8px;
    color: orange; font-weight: 700; font-size: 16px;
}

button{
    cursor: pointer;
    
}

button:active{
    opacity: 0.8;
}

.TUPP{
    width: 90%;
    font-size: 12px; color: rgba(255,255,255,0.5); text-align: center;
}

/*button[disabled]{
    border: 1px solid #fff000;
}*/

.loading{
    background: rgba(255, 196, 0, 0.5);
    position: fixed;
    width: 100%; height: 100%;
    
}

.load{
    background: #ffd400;
    position: fixed;
    width: 100%; height: 100%;
    
}

/*.centralize{
    display: flex; justify-content: center; align-items: center;
}*/

.bgVerif{
    display: block; position: fixed; top: 0; left: 0;
    width: -webkit-fill-available;  height: 100%; 
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex;  flex-direction: column; justify-content: center; 
    align-items: center; box-sizing: border-box;   z-index: 1000;  overflow: auto;
    user-select: none;
    opacity: 0; animation: fadeProgress 0.5s ease-in  forwards;
}

.verif--circle{
    display: flex; align-items: center; justify-content: center;
    width: 80px; height: 80px; background: #FEEC6E; border-radius: 100%;
     z-index: 1002; color: #000; 
}

.verif--circle::before{
    content: '';
    width: 80px; height: 80px; background: #FEEC6E; border-radius: 100%;
    display: flex; align-items: center; justify-content: center; z-index: 1001; opacity: 0.5;
    transform: scale(1); opacity: 0;
    animation: verif 0.8s ease forwards infinite; 
}

@keyframes verif{
    0%{transform: scale(1); opacity: 0.5;}100%{transform: scale(1.4); opacity: 0;}
}

.verif--text{
    font-size: 24px; color: #fff; margin-top: 16px;
}

.lembrete{
    display: flex; flex-direction: row; gap: 8px;
    width: -webkit-fill-available; background: rgba(254,236,110,0.2);
    color: #FEEC6E; margin-bottom: 16px;  
    align-items: center; justify-content: center; padding: 8px; 
}

.alert-text{
    font-size: 14px; text-align: left;
}

.iconLogin{
    font-size: 24px;
    animation: iconGiro 0.4s linear infinite;
}

@keyframes iconGiro {0%{transform: rotate(0deg);}100%{transform: rotate(360deg);}}